import { sidebarWidth } from './sidebar';

export const breakpoint = {
  mobile: 576,
  pad: 992,
};

export const theme = {
  header: {
    height: '65px',
    mobileHeight: '50px',
  },
  sidebar: {
    open: sidebarWidth.opened + 'px',
    close: sidebarWidth.closed + 'px',
  },
  text: {
    blackColor: '#111827',
    greenColor: '#27a376',
    greyColor: '#687588',
    yellowColor: '#FFC837',
    redColor: '#ee2f2f',
    orangeColor: '#ff8837',
  },
  firstColor: '#4723d9',
  firstColorLight: '#afa5d9',
  whiteColor: '#fff',
  bodyFont: "'Nunito', sans-serif",
  normalFontSize: '1rem',
  zIndexFixed: 100,
};

export type ITheme = typeof theme;

declare module 'styled-components' {
  type DefaultTheme = ITheme;
}

export type TUIType =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'neutral';

export type TUIVariant = 'contained' | 'outlined' | 'transparent';

export function getTypeStyles(
  type: TUIType = 'primary',
  variant: TUIVariant = 'contained',
) {
  const styleGenerators = {
    contained: (color: string, backgroundColor: string) => `
        color: ${color};
        background-color: ${backgroundColor};
        border: 1px solid ${backgroundColor};
        svg path {
          fill: ${color};
          stroke: ${color};
        }
        &:not(.disabled):hover {
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;   
        }
      `,
    outlined: (color: string, backgroundColor: string) => `
        color: ${backgroundColor};
        background-color: ${color};
        border: 1px solid ${backgroundColor};
        svg path {
          fill: ${backgroundColor} !important;
        }
        &:not(.disabled):hover {
          background-color: rgba(0, 0, 0, 0.04); /* Adds a slight hover effect */
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    
        }
      `,
    transparent: (color: string, backgroundColor: string) => `
        color: ${backgroundColor};
        background-color: transparent;
        border: none;
        svg path {
          fill: ${backgroundColor} !important;
        }
        &:not(.disabled):hover {
          text-decoration: underline;
        }
      `,
  };

  // Define color mappings
  const colors: Record<TUIType, { color: string; backgroundColor: string }> = {
    success: { color: '#FFFFFF', backgroundColor: '#0CAF60' },
    warning: { color: '#FFFFFF', backgroundColor: '#FFC107' },
    danger: { color: '#FFFFFF', backgroundColor: '#E03137' },
    info: { color: '#FFFFFF', backgroundColor: '#007BFF' },
    primary: { color: '#FFFFFF', backgroundColor: '#111827' },
    neutral: { color: '#000', backgroundColor: '#F5F5F5' },
  };

  const { color, backgroundColor } = colors[type];

  const generateStyle = styleGenerators[variant];
  return generateStyle(color, backgroundColor);
}
