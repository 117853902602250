import React, { useEffect, useMemo, useState } from 'react';
import { Abbreviation, Badge, Button, FormContainer, TitleCard } from '../ui';
import {
  ELeadDurationType,
  ELeadStatus,
  ELeadType,
  HostedFile,
  Lead,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatCurrency,
  formatDate,
  formatDateTime,
} from '../../utils';
import { DocumentsListComponent } from '../document';
import { toast } from 'react-toastify';
import { TUIType } from '../../constants';
import { PlusIcon } from '../../icons';
import { calculateProgressLead } from './lead-form';
import { useModal } from '../../contexts/side-modal.context';
import {
  calculateBenchmark,
  calculateMarginWithPercentage,
} from './lead-form/lead-financial-form-step';
import { useNavigate } from 'react-router-dom';

type ViewLeadProps = {
  lead: Lead;
};

export const leadStatusValueType = (value: ELeadStatus): TUIType => {
  switch (value) {
    case ELeadStatus.NewOpportunity:
      return 'neutral';
    case ELeadStatus.MeetingScheduled:
      return 'info';
    case ELeadStatus.ProposalSent:
      return 'info';
    case ELeadStatus.ContractSigned:
      return 'success';
    case ELeadStatus.ContractReceived:
      return 'warning';
    case ELeadStatus.OperationalGoLive:
      return 'success';
    default:
      return 'primary';
  }
};

// export const leadTagValueType = (value: ELeadTag): TUIType => {
//   switch (value) {
//     case ELeadTag.Live:
//       return 'info';
//     case ELeadTag.NonProgressive:
//       return 'neutral';
//     case ELeadTag.Converted:
//       return 'info';
//     default:
//       return 'info';
//   }
// };

export const leadTypeValueType = (value: ELeadType): TUIType => {
  switch (value) {
    case ELeadType.New:
      return 'success';
    case ELeadType.Extension:
      return 'warning';
    default:
      return 'info';
  }
};

export const ViewLead = ({ lead }: ViewLeadProps) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const { closeModal } = useModal();

  const calculateProgress = useMemo(() => {
    if (!lead) return 0;
    return calculateProgressLead(lead);
  }, [lead]);

  const margin = useMemo(() => {
    return calculateMarginWithPercentage(lead.revenue ?? 0, lead.cost ?? 0);
  }, [lead]);

  const benchmark = useMemo(() => {
    return calculateBenchmark(
      lead?.cost ?? 0,
      lead?.durationValue ?? 0,
      lead?.durationType ?? ELeadDurationType.Day,
    );
  }, [lead.cost, lead.durationValue, lead.durationType]);

  const createProject = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerCreateProjectViaLead({ leadId: lead._id })
      .then((result) => {
        const project = result.data;
        toast.success('General Project saved successfully');
        navigate(`/${ERoute.PROJECTS}/${project._id}`);
      })
      .catch((e) => {
        toast.error(
          'Could not create a project for this lead, please try again or contact Admin',
        );
      });
  };

  const getLeadDocuments = () => {
    AxiosInstance.files
      .filesControllerFindLeadDocuments(lead._id!)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getLeadDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    getLeadDocuments();
  }, []);

  return (
    <FormContainer className="view-form" data-testid="lead-overview-form">
      <TitleCard data-testid="lead-overview-title">Overview</TitleCard>
      <div className="row">
        <div className="col-md-12" data-testid="lead-date-added">
          <div className="data-title">Date Added</div>
          <div className="data-value">{formatDate(lead.createdAt ?? '')}</div>
        </div>
        <div className="col-md-6" data-testid="lead-cpms">
          <div className="data-title">CPM</div>
          <div className="data-value cpms-list">
            <Badge type="neutral">
              <Abbreviation>
                {lead.cpm.firstName[0]}
                {lead.cpm.lastName[0]}
              </Abbreviation>
              {lead.cpm.firstName} {lead.cpm.lastName}
            </Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-trust-hospital">
          <div className="data-title">Specialty</div>
          <div className="data-value">
            <Badge type="neutral">{lead.specialty}</Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-status">
          <div className="data-title">Status</div>
          <div className="data-value">
            <Badge type={leadStatusValueType(lead.status)}>{lead.status}</Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-type">
          <div className="data-title">Type</div>
          <div className="data-value">
            <Badge type={leadTypeValueType(lead.type)}>{lead.type}</Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-region">
          <div className="data-title">Region</div>
          <div className="data-value">
            <Badge type="neutral">{lead.region}</Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-duration">
          <div className="data-title">Duration</div>
          <div className="data-value">
            {lead.durationValue ? (
              <>
                {lead.durationValue} ({lead.durationType})
              </>
            ) : (
              <>-</>
            )}
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-trust-hospital">
          <div className="data-title">Trust</div>
          <div className="data-value">
            <Badge type="neutral">{lead.trust?.name ?? '-'}</Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-region">
          <div className="data-title">Hospital</div>
          <div className="data-value">
            <Badge type="neutral">{lead.hospital?.clientName ?? '-'}</Badge>
          </div>
        </div>
        <TitleCard data-testid="lead-financial-title">Contact</TitleCard>
        <div className="col-md-6" data-testid="lead-cpms">
          <div className="data-title">Name</div>
          <div className="data-value cpms-list">
            <Badge type="neutral">
              <Abbreviation>
                {lead.clientDepartment.firstNameContact[0]}
                {lead.clientDepartment.lastNameContact[0]}
              </Abbreviation>
              {lead.clientDepartment.firstNameContact}{' '}
              {lead.clientDepartment.lastNameContact}
            </Badge>
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-cpms">
          <div className="data-title">Job Title</div>
          <div className="data-value">
            {lead.clientDepartment.jobTitleContact}
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-cpms">
          <div className="data-title">Email</div>
          <div className="data-value">{lead.clientDepartment.emailContact}</div>
        </div>
        <div className="col-md-6" data-testid="lead-cpms">
          <div className="data-title">Email</div>
          <div className="data-value">
            {lead.clientDepartment.phoneNumberContact}
          </div>
        </div>
        {/* <div className="col-md-12" data-testid="lead-overview">
          <div className="data-title">Overview</div>
          <div className="data-value">{lead.overview}</div>
        </div>
        <div className="col-md-6" data-testid="lead-starting-month">
          <div className="data-title">Starting Month</div>
          <div className="data-value">
            {monthsList.find((el) => el.value === lead.startingMonth)?.label}
          </div>
        </div> */}

        <TitleCard data-testid="lead-financial-title">Financial</TitleCard>
        <div className="col-md-6" data-testid="lead-revenue">
          <div className="data-title">Revenue</div>
          <div className="data-value">
            {lead.revenue ? formatCurrency(lead.revenue) : '-'}
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-cost">
          <div className="data-title">Cost</div>
          <div className="data-value">
            {lead.cost ? formatCurrency(lead.cost) : '-'}
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-margin">
          <div className="data-title">Margin</div>
          <div className="data-value">
            {margin
              ? `${formatCurrency(margin.value)} (${margin.percentage}%)`
              : '-'}
          </div>
        </div>
        <div className="col-md-6" data-testid="lead-cashflow-benchmark">
          <div className="data-title">Cashflow Benchmark (90 days)</div>
          <div className="data-value">
            {benchmark ? formatCurrency(benchmark) : '-'}
          </div>
        </div>
      </div>

      <TitleCard data-testid="lead-legal-title">Legal</TitleCard>
      <div className="row">
        <div className="col-md-12" data-testid="lead-legal-contact">
          <div className="data-title">Contact</div>
          <div className="data-value">
            {lead.legalContact ? (
              <>
                {lead.legalContact.firstName} {lead.legalContact.lastName}
              </>
            ) : (
              <>Unassigned</>
            )}
          </div>
        </div>
        <div className="col-md-12" data-testid="lead-contracts-documents">
          <div className="data-title">Contracts & documents</div>
          <DocumentsListComponent
            documents={documents}
            getDocuments={getLeadDocuments}
            onClickDelete={onClickDelete}
          />
        </div>

        <TitleCard data-testid="lead-notes-title">Meeting Notes</TitleCard>
        {lead.notes?.length ? (
          <>
            {lead.notes.map((el) => (
              <>
                <div className="col-md-12" data-testid="lead-revenue">
                  <div className="data-title">
                    <div>
                      {el.user.firstName} {el.user.lastName}
                    </div>
                    <div>{formatDateTime(el.createdAt)}</div>
                  </div>
                  <div className="data-value">{el.text}</div>
                </div>
              </>
            ))}
          </>
        ) : (
          <div className="w-100 text-center" data-testid="no-notes-found">
            No item found
          </div>
        )}
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button type="danger" variant="outlined" onClick={() => closeModal()}>
          Close
        </Button>
        {!lead.project && (
          <Button
            onClick={() => createProject(lead)}
            type="success"
            variant="outlined"
            disabled={calculateProgress !== 100}
          >
            <PlusIcon /> Create Project from Lead
          </Button>
        )}
      </div>
    </FormContainer>
  );
};
