import { useEffect, useMemo, useState } from 'react';
import {
  CardContainer,
  HeaderPageContainer,
  TitlePage,
  SubTitlePage,
  Table,
} from '../../components';
import { AxiosInstance } from '../../utils';
import { ReportingResultDto } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { BarChart } from '../../components/charts/bar-chart';
import { chartsColors } from '../../constants/colors';
import { LineChart } from '../../components/charts/line-chart';
type ClientChargeDto = {
  client: string;

  specialty: string;

  totalCharge: number;
};
export const ReportingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [weeksPerMonthList, setWeeksPerMonthList] = useState<{
    [month: string]: number[];
  }>({});

  const [reportingData, setReportingData] = useState<{
    [week: number]: ReportingResultDto;
  }>({});

  const [clientSpecialtyGraphData, setClientSpecialtyGraphData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  }>();

  const [grossMarginGraphData, setGrossMarginGraphData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  }>();

  const [grossMarginPercentageGraphData, setGrossMarginPercentageGraphData] =
    useState<{
      labels: string[];
      datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
      }[];
    }>();
  const generateWeeksPerMonth = (year: number) => {
    const weeksPerMonthList: { [month: string]: number[] } = {};
    const startDate = new Date(year, 0, 1);
    let index = 1;

    while (startDate.getFullYear() === year) {
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      const month = endDate.toLocaleString('default', { month: 'long' });

      if (endDate.getFullYear() === year + 1) break;

      weeksPerMonthList[month] = weeksPerMonthList[month] ?? [];

      weeksPerMonthList[month] = [...weeksPerMonthList[month], index];

      startDate.setDate(startDate.getDate() + 7);
      index++;
    }

    return weeksPerMonthList;
  };

  const fetchReporting = () => {
    AxiosInstance.projects
      .projectsControllerReporting()
      .then((response) => {
        const result: { [week: number]: ReportingResultDto } = {};

        response.data.forEach((item) => {
          result[item.week] = item;
        });
        setReportingData(result);

        const weeks: number[] = [];
        const grossMarginDataSetMap: {
          label: string;
          data: number[];
          backgroundColor: string;
        } = {
          label: '',
          data: [],
          backgroundColor: '#94cced',
        };

        const grossMarginPercentageDataSetMap: {
          label: string;
          data: number[];
          backgroundColor: string;
          borderColor: string;
        } = {
          label: '',
          data: [],
          backgroundColor: '#94cced',
          borderColor: '#94cced',
        };

        Array(52)
          .fill(0)
          .forEach((element, index) => {
            const week = index + 1;

            weeks.push(week);

            grossMarginDataSetMap.data.push(result[week]?.grossMargin || 0);
            grossMarginPercentageDataSetMap.data.push(
              result[week]?.grossMarginPercentage || 0,
            );
          });

        // const datasets = Object.values(datasetMap);

        setGrossMarginGraphData({
          labels: weeks,
          datasets: [grossMarginDataSetMap],
        } as any);

        setGrossMarginPercentageGraphData({
          labels: weeks,
          datasets: [grossMarginPercentageDataSetMap],
        } as any);
      })
      .catch(() => {
        toast.error('Failed to fetch Reporting data');
      });

    AxiosInstance.projects
      .projectsControllerTotalChargeByClientAndSpecialty()
      .then((response) => {
        const weeksData = response.data.weeks as Record<
          string,
          ClientChargeDto[]
        >;

        const colorMap: any = {};

        let colorIndex = 0;

        const weeks = Object.keys(weeksData).map((week) => parseInt(week));
        const datasetMap: any = {};

        weeks.forEach((week) => {
          weeksData[week].forEach((entry) => {
            const key = `${entry.specialty} - ${entry.client}`;
            if (!datasetMap[key]) {
              if (!colorMap[key]) {
                colorMap[key] = chartsColors[colorIndex % chartsColors.length];
                colorIndex++; // Move to the next color
              }
              datasetMap[key] = {
                label: key,
                data: Array(52).fill(0),
                backgroundColor: colorMap[key],
                // stack: 'Stack 0',
              };
            }
            datasetMap[key].data[week - 1] = entry.totalCharge;
          });
        });

        const datasets = Object.values(datasetMap);

        const data = {
          labels: weeks,
          datasets: datasets,
        };
        setClientSpecialtyGraphData(data as any);

        console.log({ data });
      })
      .catch(() => {
        toast.error('Failed to fetch Reporting data');
      });
  };

  useEffect(() => {
    setWeeksPerMonthList(generateWeeksPerMonth(new Date().getFullYear()));
    fetchReporting();
  }, []);

  const totalRevenue = useMemo(() => {
    return Object.values(reportingData).reduce(
      (acc, item) => acc + item.totalRevenue,
      0,
    );
  }, [reportingData]);

  const totalCharge = useMemo(() => {
    return Object.values(reportingData).reduce(
      (acc, item) => acc + item.totalCharge,
      0,
    );
  }, [reportingData]);

  const totalBCVCharge = useMemo(() => {
    return Object.values(reportingData).reduce(
      (acc, item) => acc + item.totalBcvCharge,
      0,
    );
  }, [reportingData]);

  const grossMargin = useMemo(() => {
    return totalCharge + totalBCVCharge - totalRevenue;
  }, [totalRevenue, totalCharge, totalBCVCharge]);

  const grossMarginPercentage = useMemo(() => {
    return ((totalCharge + totalBCVCharge) / totalRevenue).toFixed(2);
  }, [grossMargin, totalRevenue]);
  return (
    <CardContainer
      className="project-list-card-container"
      data-testid="project-list-card-container"
    >
      <HeaderPageContainer data-testid="header-page-container">
        <div className="left-container" data-testid="left-container">
          <TitlePage data-testid="title-page">Projects</TitlePage>
          <SubTitlePage data-testid="subtitle-page">Reporting</SubTitlePage>
        </div>
      </HeaderPageContainer>

      <div className="data-table-container" data-testid="data-table-container">
        <div className="table-scroll-container" style={{ overflowX: 'auto' }}>
          <Table data-testid="table">
            <thead>
              <tr>
                <th className="first-cell"></th>
                <th className="first-cell text-center">Total</th>

                {Object.keys(weeksPerMonthList).map((month) => {
                  return (
                    <th className="month">
                      <div className="month-label">{month}</div>
                      <div className="weeks-container">
                        {weeksPerMonthList[month].map((week) => {
                          return (
                            <th>
                              <div>
                                <label>{week}</label>
                              </div>
                            </th>
                          );
                        })}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  <>
                    <tr>
                      <td>
                        {' '}
                        <div className="">
                          <label>Total Client Charge</label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{totalCharge}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]?.totalCharge || 0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <td>
                        {' '}
                        <div className="">
                          <label>Total BCV Charge </label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{totalBCVCharge}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]?.totalBcvCharge ||
                                          0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>

                    <tr className="highlighted-cell">
                      <td>
                        {' '}
                        <div>
                          <label>Total Income </label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{totalCharge + totalBCVCharge}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]?.totalCharge +
                                          reportingData[week]?.totalBcvCharge ||
                                          0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <div className="">
                          <label>Total Cost Of Sale </label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{totalRevenue}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]?.totalRevenue || 0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <td>
                        {' '}
                        <div className="">
                          <label>Gross Margin </label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{grossMargin}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]?.grossMargin || 0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <td>
                        {' '}
                        <div className="">
                          <label>Gross Margin % </label>
                        </div>
                      </td>

                      <td className="text-center">
                        {' '}
                        <div>
                          <label>{grossMarginPercentage}</label>
                        </div>
                      </td>

                      {Object.keys(weeksPerMonthList).map((month) => {
                        return (
                          <td className="month">
                            <div className="weeks-container">
                              {weeksPerMonthList[month].map((week) => {
                                return (
                                  <td>
                                    <div>
                                      <label>
                                        {reportingData[week]
                                          ?.grossMarginPercentage || 0}
                                      </label>
                                    </div>
                                  </td>
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </>
                </>
              )}
            </tbody>
          </Table>
        </div>

        <br></br>
        {grossMarginGraphData && (
          <BarChart
            data={grossMarginGraphData}
            config={{
              labels: [],
              backgroundColors: [],
              borderColors: [],
              title: 'Weekly Gross Margins - Consolidated (in £)',
              subtitle: '',
              displayLegend: false,
              displayCenterValues: false,
            }}
            isLoading={isLoading}
          />
        )}
        <br></br>
        {grossMarginPercentageGraphData && (
          <LineChart
            data={grossMarginPercentageGraphData}
            config={{
              labels: [],
              backgroundColors: [],
              borderColors: [],
              title: 'Weekly Gross Margin - Consolidated (in percentage)',
              subtitle: '',
              displayLegend: false,
              displayCenterValues: false,
            }}
            isLoading={isLoading}
          />
        )}
        <br></br>

        {clientSpecialtyGraphData && (
          <BarChart
            data={clientSpecialtyGraphData}
            config={{
              labels: [],
              backgroundColors: [],
              borderColors: [],
              title: "Weekly Sales by Client's Specialty (Stacked, in £)",
              subtitle: '',
              displayCenterValues: true,
            }}
            isLoading={isLoading}
          />
        )}
      </div>
    </CardContainer>
  );
};
