import * as yup from 'yup';

export const generalProjectSchema = yup.object().shape({
  name: yup.string(),

  specialty: yup.string().required('Specialty is required'),

  cpmIds: yup
    .array()
    .of(yup.string().required('Each CPM must be a string'))
    .required('CPM are required')
    .min(1, 'At least one CPM is required'),

  serviceCoordinatorId: yup
    .string()
    .required('Service Coordinator is required'),

  cemId: yup.string().required('CEM is required'),

  trustId: yup.string(),

  clientId: yup.string().required('Hospital is required'),

  region: yup.string().required('Region is required'),

  report: yup.string().required('Report is required'),
});

export const activityProjectSchema = yup.object().shape({
  activities: yup
    .array(
      yup.object().shape({
        subSpecialty: yup.string().required('Sub specialty is required'),
        activityType: yup.string().required('Activity type is required'),
        startDate: yup
          .string()
          .required('Start date is required')
          .test(
            'is-date',
            'Invalid date format',
            (value) => !value || !isNaN(Date.parse(value)),
          ),
        endDate: yup
          .string()
          .required('End date is required')
          .test(
            'is-date',
            'Invalid date format',
            (value) => !value || !isNaN(Date.parse(value)),
          )
          .test(
            'is-after-start',
            'End date must be after start date',
            function (endDate) {
              const { startDate } = this.parent;
              return (
                !startDate ||
                !endDate ||
                Date.parse(endDate) > Date.parse(startDate)
              );
            },
          ),
        clientDepartmentId: yup
          .string()
          .required('Activity Contact is required'),
        rateType: yup.string().required('Type is required'),
        rate: yup.number().required('Rate is required'),
        template: yup.number().required('Template is required'),
        bcv: yup.number().required('BCV is required'),
        roles: yup
          .array(
            yup.object().shape({
              level: yup.string().required('Level is required'),
              grade: yup.string().required('Grade is required'),
              // startTime: yup
              //   .string()
              //   .required('Start time is required')
              //   .test(
              //     'is-time',
              //     'Invalid time format (HH:mm expected)',
              //     (value) => !value || /^\d{2}:\d{2}$/.test(value),
              //   ),
              // endTime: yup
              //   .string()
              //   .required('End time is required')
              //   .test(
              //     'is-time',
              //     'Invalid time format (HH:mm expected)',
              //     (value) => !value || /^\d{2}:\d{2}$/.test(value),
              //   ),
              rate: yup
                .number()
                .required('Rate is required')
                .typeError('Rate must be a number')
                .positive('Rate must be positive'),
              expanse: yup
                .number()
                .required('Expanse is required')
                .typeError('Expanse must be a number')
                .min(0, 'Expanse cannot be negative'),
            }),
          )
          .required('Roles are required'),
      }),
    )
    .required('At least one activity is required'),
});

export const singleActivitySchema = yup.object().shape({
  subSpecialty: yup.string().required('Sub specialty is required'),
  activityType: yup.string().required('Activity type is required'),
  startDate: yup
    .string()
    .required('Start date is required')
    .test(
      'is-date',
      'Invalid date format',
      (value) => !value || !isNaN(Date.parse(value)),
    ),
  endDate: yup
    .string()
    .required('End date is required')
    .test(
      'is-date',
      'Invalid date format',
      (value) => !value || !isNaN(Date.parse(value)),
    )
    .test(
      'is-after-start',
      'End date must be after start date',
      function (endDate) {
        const { startDate } = this.parent;
        return (
          !startDate || !endDate || Date.parse(endDate) > Date.parse(startDate)
        );
      },
    ),
  clientDepartmentId: yup.string().required('Activity Contact is required'),
  rateType: yup.string().required('Type is required'),
  rate: yup
    .number()
    .required('Rate is required')
    .typeError('Rate must be a number')
    .positive('Rate must be positive'),
  template: yup
    .number()
    .required('Template is required')
    .typeError('Template must be a number')
    .positive('Template must be positive'),
  bcv: yup
    .number()
    .required('BCV is required')
    .typeError('BCV must be a number')
    .positive('BCV must be positive'),
});

export const singleRoleSchema = yup.object().shape({
  level: yup.string().required('Level is required'),
  grade: yup.string().required('Grade is required'),
  // startTime: yup
  //   .string()
  //   .required('Start time is required')
  //   .test(
  //     'is-time',
  //     'Invalid time format (HH:mm expected)',
  //     (value) => !value || /^\d{2}:\d{2}$/.test(value),
  //   ),
  // endTime: yup
  //   .string()
  //   .required('End time is required')
  //   .test(
  //     'is-time',
  //     'Invalid time format (HH:mm expected)',
  //     (value) => !value || /^\d{2}:\d{2}$/.test(value),
  //   ),
  rate: yup
    .number()
    .required('Rate is required')
    .typeError('Rate must be a number')
    .positive('Rate must be positive'),

  expanse: yup
    .number()
    .required('Expanse is required')
    .typeError('Expanse must be a number')
    .min(0, 'Expanse cannot be negative'),
});

export const financialProjectSchema = yup.object().shape({
  contactId: yup.string().required('Contact is required'),

  revenuePer: yup.string().required('Revenue Type is required'),

  revenue: yup
    .number()
    .required('Revenue is required')
    .typeError('Revenue must be a number'),

  cost: yup
    .number()
    .required('Cost is required')
    .typeError('Cost must be a number'),

  purchaseOrderNumber: yup
    .number()
    .required('Purchase Order Number is required')
    .typeError('Purchase Order Number must be a number'),

  invoicingTo: yup.string().required('Invoicing To is required'),

  invoicingCC: yup.string().required('Invoicing CC is required'),

  staffPayFrequency: yup.string().required('Staff Pay Frequency is required'),

  paymentTerms: yup.string().required('Payment Terms are required'),

  invoiceFrequency: yup.string().required('Invoice Frequency is required'),
});

export const legalProjectSchema = yup.object().shape({
  legalContactId: yup.string().required('Legal contact is required'),
});
