import React, { useEffect, useState } from 'react';
import {
  DraggableArea,
  PipelineContainer,
  PipelineStatus,
} from '../ui/pipeline/pipeline';
import { PlusIcon } from '../../icons';
import { ApplicationCardPipeline } from '../ui/pipeline/application-card-pipeline';
import {
  Application,
  UpdateApplicationStatusDto,
} from '../../backend/careo-api';
import { applicationProjectStatusList } from '../../constants';
import { rejectedPlacementStatuses } from '../../pages/placement/placements-list/placements-list.page';
import { AxiosInstance } from '../../utils';

type ProjectMatchingPipelineProps = {
  applicationsList: Application[];
  getApplications: () => void;
};

export const ProjectMatchingPipeline = ({
  applicationsList,
  getApplications,
}: ProjectMatchingPipelineProps) => {
  const [pipelines, setPipelines] = useState<PipelineStatus<Application>[]>(
    applicationProjectStatusList.map((el) => ({ ...el, items: [] })),
  );

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = applicationProjectStatusList[newListIndex].value;

    await AxiosInstance.applications.applicationsControllerUpdateApplicationStatus(
      id,
      {
        status: newStatus,
      } as UpdateApplicationStatusDto,
    );
  };

  useEffect(() => {
    const response = applicationsList.filter(
      (el) => !rejectedPlacementStatuses.includes(el.approvalStatus!),
    );
    const groupedApplications = response.reduce((acc: any, curr) => {
      const status = curr.status; // Assuming status is a property of the application model
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(curr);
      return acc;
    }, {});

    setPipelines((prev) => {
      prev = prev.map((el) => ({
        ...el,
        items: groupedApplications[el.value] ?? [],
      }));

      return prev;
    });
  }, [applicationsList]);

  return (
    <PipelineContainer data-testid="pipeline-container">
      {pipelines.map((el, listIndex: number) => (
        <div>
          <div
            className="item-container"
            key={listIndex}
            data-testid={`item-container-${listIndex}`}
          >
            <div
              className="item-header"
              data-testid={`item-header-${listIndex}`}
            >
              <div
                className="item-title"
                data-testid={`item-title-${listIndex}`}
              >
                <label data-testid={`item-label-${listIndex}`}>
                  {el.label}
                </label>
                <div
                  className="item-total"
                  data-testid={`item-total-${listIndex}`}
                >
                  {el.items.length}
                </div>
              </div>
              <PlusIcon data-testid={`plus-icon-${listIndex}`} />
            </div>
            <div
              className="cards-list-container"
              data-testid={`cards-list-container-${listIndex}`}
            >
              {el.isDraggableTo ? (
                <DraggableArea
                  pipeline={pipelines}
                  listIndex={listIndex}
                  itemIndex={0}
                  onSuccess={getApplications}
                  onDragRequest={handleDrop}
                  data-testid={`draggable-area-${listIndex}`}
                />
              ) : (
                <div
                  className="draggable-area false"
                  data-testid={`draggable-area-false-${listIndex}`}
                >
                  <hr />
                </div>
              )}
              {el.items
                .sort(
                  (a, b) =>
                    new Date(a.availableFrom).getTime() -
                    new Date(b.availableFrom).getTime(),
                )
                .map((item, itemIndex: number) => (
                  <div
                    key={item._id}
                    data-testid={`application-card-${item._id}`}
                  >
                    <ApplicationCardPipeline
                      item={item}
                      listIndex={listIndex}
                      itemIndex={itemIndex}
                      onClickEdit={() => {}}
                      data-testid={`application-card-${item._id}`}
                    />
                    {el.isDraggableTo ? (
                      <DraggableArea
                        pipeline={pipelines}
                        listIndex={listIndex}
                        itemIndex={0}
                        onSuccess={getApplications}
                        onDragRequest={handleDrop}
                        data-testid={`draggable-area-${listIndex}-${itemIndex}`}
                      />
                    ) : (
                      <div
                        className="draggable-area false"
                        data-testid={`draggable-area-false-${listIndex}-${itemIndex}`}
                      >
                        <hr />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
    </PipelineContainer>
  );
};
