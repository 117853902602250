import React, { useEffect, useState } from 'react';
import { SelectContainer } from './select.style';
import { Controller, FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

export type OptionType = {
  value: string | number;
  label: string;
  isDisabled?: boolean;
};

type FieldTextProps = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: FieldError;
  onClick?: () => void;
  onChange?: (value: string | number) => void;
  options: OptionType[];
  value?: string | number;
  register?: UseFormRegisterReturn<any>;
  control?: any;
  withoutChip?: boolean; // New prop
  'data-testid'?: string;
};

export const Select = ({
  label,
  value,
  onChange,
  required,
  options,
  register,
  className,
  disabled,
  error,
  placeholder,
  onClick,
  control,
  withoutChip = false, // Default to false if not provided
  'data-testid': dataTestId,
}: FieldTextProps) => {
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    value ?? null,
  );

  const handleChange = (event: any, target: OptionType | null) => {
    const newValue = target?.value ?? '';
    setSelectedValue(newValue);

    if (register) {
      register.onChange?.({
        target: {
          value: newValue,
          name: register.name,
        },
      });
    }

    onChange?.(newValue);
  };

  useEffect(() => {
    setSelectedValue(value ?? null);
  }, [value]);

  return (
    <SelectContainer
      className={`select-container ${className ?? ''}`}
      hasError={!!error}
      onClick={() => onClick?.()}
      data-testid={dataTestId}
    >
      {label && (
        <label htmlFor="">
          {label} {required && <span>*</span>}
        </label>
      )}

      {control ? (
        <Controller
          control={control}
          name={register?.name ?? ''}
          render={({
            field: { onChange: fieldOnChange, value: fieldValue },
          }) => (
            <Autocomplete
              disablePortal
              value={fieldValue ?? null}
              getOptionLabel={(option) =>
                options.find((el) => el.value === option)?.label ?? ''
              }
              renderOption={(props, option: any) => (
                <li {...props}>{option.label}</li>
              )}
              options={options}
              onChange={(event, target) => {
                const value = (target as OptionType)?.value;
                fieldOnChange(value);
                onChange?.(value);
                register?.onChange?.({
                  target: {
                    value: value,
                    name: register.name,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  className="mui-input"
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    // startAdornment:
                    //   !withoutChip && fieldValue ? (
                    //     <Badge type="neutral">
                    //       {options.find((option) => option.value === fieldValue)
                    //         ?.label || ''}{' '}
                    //     </Badge>
                    //   ) : null,
                  }}
                />
              )}
              filterOptions={(options: OptionType[], state) =>
                options.filter((el) =>
                  el.label
                    .toLocaleLowerCase()
                    .includes(state.inputValue.toLocaleLowerCase()),
                )
              }
              disabled={disabled}
            />
          )}
        />
      ) : (
        <Autocomplete
          disablePortal
          options={options}
          onChange={handleChange}
          value={options.find((el) => el.value === selectedValue) ?? null}
          renderInput={(params) => (
            <TextField
              className="mui-input"
              {...params}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                // startAdornment:
                //   !withoutChip && selectedValue ? (
                //     <Badge type="neutral">
                //       {options.find((option) => option.value === selectedValue)
                //         ?.label || ''}
                //     </Badge>
                //   ) : null,
              }}
            />
          )}
          disabled={disabled}
        />
      )}
      {error && <span className="error-container">{error?.message}</span>}
    </SelectContainer>
  );
};
