import { FormContainer, TitlePage } from '../ui';
import {
  FinancialFormStepProps,
  LeadFinancialFormStep,
} from './lead-form/lead-financial-form-step';

export const UpdateLeadFinanCialForm = ({
  step,
  setStep,
  createdLead,
  setLead,
  onSuccess: getLeads,
}: FinancialFormStepProps) => {
  return (
    <FormContainer data-testid="form-container">
      <div className="side-modal-header-container">
        <TitlePage className="side-modal-title">Lead Financial</TitlePage>
      </div>

      <LeadFinancialFormStep
        step={step}
        setStep={setStep}
        createdLead={createdLead}
        setLead={setLead}
        data-testid="financial-form-step"
        onSuccess={getLeads}
        showModalActionBtns={true}
      />
    </FormContainer>
  );
};
