import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .left-container {
    flex-direction: column;
  }
  .right-container {
    display: flex;
    align-items: center;
    gap: 40px;
    flex: 1;
    justify-content: flex-end;
    flex-wrap: wrap;
    .budget-container {
      font-size: 12px;
      font-weight: 700;
      .title {
      }
      .numbers {
        .green {
          color: #0caf60;
        }
      }
    }
  }

  &.page-summary-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    > div {
      display: flex;
    }
    .module-name {
      display: flex;
      gap: 12px;
      align-items: center;
      .module-info {
        .name {
          font-size: 18px;
          font-weight: 500;
        }
        .availability {
          font-size: 14px;
          font-weight: 400;
          color: #a0aec0;
        }
      }
    }
    .module-contact {
      display: flex;
      gap: 12px;
      font-size: 16px;
      svg {
        path {
          fill: #a0aec0;
        }
      }
    }
    .actions-container {
      display: flex;
      gap: 12px;
    }
    .edit-container {
      background-color: #2f78ee;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      svg {
        path {
          fill: white;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: gray;
        border: none;
        background-color: #e9ecef;
        svg > path {
          fill: gray;
        }
      }
    }
  }

  .filter-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    select {
      max-width: 170px;
      width: 170px;
    }
    .search-input {
      min-width: 200px;
    }

    .mui-input {
      div {
        input {
          height: unset;
          outline: none;
          border: none;
          width: 120px;
        }
      }
    }

    select,
    input {
      /* height: 54px; */
      /* outline: none;
      border: 1px solid #e9eaec;
      border-radius: 10px; */
    }
  }

  .select-checkbox-action-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .number-selected-items {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    .checkbox-action-buttons-container {
      display: flex;
    }
  }

  .data-table-container {
    overflow-x: auto;
  }

  .candidates-names {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 18px;
    gap: 18px;

    &.page-summary-container {
      gap: 5px;
      .module-name {
        gap: 5px;
        .module-info {
          .name {
            font-size: 14px;
          }
          .availability {
            font-size: 10px;
          }
        }
      }
      .module-contact {
        gap: 5px;
        font-size: 12px;
      }
      .actions-container {
        gap: 5px;
      }
    }

    .filter-container {
      gap: 10px;
    }

    .select-checkbox-action-container {
      gap: 5px;
      .number-selected-items {
        font-size: 8px;
      }
    }
  }
`;
