import { useEffect, useState } from 'react';
import {
  CardContainer,
  HeaderPageContainer,
  TitlePage,
  SubTitlePage,
  Table,
} from '../../components';

export const ReportingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [weeksPerMonthList, setWeeksPerMonthList] = useState<{
    [month: string]: number[];
  }>({});

  const generateWeeksPerMonth = (year: number) => {
    const weeksPerMonthList: { [month: string]: number[] } = {};
    const startDate = new Date(year, 0, 1);
    let index = 1;

    while (startDate.getFullYear() === year) {
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      const month = endDate.toLocaleString('default', { month: 'long' });

      if (endDate.getFullYear() === year + 1) break;

      weeksPerMonthList[month] = weeksPerMonthList[month] ?? [];

      weeksPerMonthList[month] = [...weeksPerMonthList[month], index];

      startDate.setDate(startDate.getDate() + 7);
      index++;
    }

    console.log({ weeksPerMonthList });
    return weeksPerMonthList;
  };

  useEffect(() => {
    setWeeksPerMonthList(generateWeeksPerMonth(new Date().getFullYear()));
  }, []);
  return (
    <CardContainer
      className="project-list-card-container"
      data-testid="project-list-card-container"
    >
      <HeaderPageContainer data-testid="header-page-container">
        <div className="left-container" data-testid="left-container">
          <TitlePage data-testid="title-page">Projects</TitlePage>
          <SubTitlePage data-testid="subtitle-page">Reporting</SubTitlePage>
        </div>
      </HeaderPageContainer>

      <div className="data-table-container" data-testid="data-table-container">
        <Table data-testid="table">
          <thead>
            <tr>
              <th className="first-cell"></th>
              <th className="first-cell text-center">Total</th>

              {Object.keys(weeksPerMonthList).map((month) => {
                return (
                  <th className="month">
                    <div className="month-label">{month}</div>
                    <div className="weeks-container">
                      {weeksPerMonthList[month].map((week) => {
                        return (
                          <th>
                            <div>
                              <label>{week}</label>
                            </div>
                          </th>
                        );
                      })}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr data-testid="loading-row">
                <td
                  colSpan={100}
                  className="text-center"
                  data-testid="loading-text"
                >
                  Loading ...
                </td>
              </tr>
            ) : (
              <>
                <>
                  <tr>
                    <td>
                      {' '}
                      <div className="">
                        <label>Total Income </label>
                      </div>
                    </td>

                    <td className="text-center">
                      {' '}
                      <div>
                        <label>0 </label>
                      </div>
                    </td>

                    {Object.keys(weeksPerMonthList).map((month) => {
                      return (
                        <td className="month">
                          <div className="weeks-container">
                            {weeksPerMonthList[month].map((week) => {
                              return (
                                <td>
                                  <div>
                                    <label>{0}</label>
                                  </div>
                                </td>
                              );
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>
                      {' '}
                      <div className="">
                        <label>Total Cost Of Sale </label>
                      </div>
                    </td>

                    <td className="text-center">
                      {' '}
                      <div>
                        <label>0 </label>
                      </div>
                    </td>

                    {Object.keys(weeksPerMonthList).map((month) => {
                      return (
                        <td className="month">
                          <div className="weeks-container">
                            {weeksPerMonthList[month].map((week) => {
                              return (
                                <td>
                                  <div>
                                    <label>{0}</label>
                                  </div>
                                </td>
                              );
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>
                      {' '}
                      <div className="">
                        <label>Gross Margin </label>
                      </div>
                    </td>

                    <td className="text-center">
                      {' '}
                      <div>
                        <label>0 </label>
                      </div>
                    </td>

                    {Object.keys(weeksPerMonthList).map((month) => {
                      return (
                        <td className="month">
                          <div className="weeks-container">
                            {weeksPerMonthList[month].map((week) => {
                              return (
                                <td>
                                  <div>
                                    <label>{0}</label>
                                  </div>
                                </td>
                              );
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>
                      {' '}
                      <div className="">
                        <label>Gross Margin % </label>
                      </div>
                    </td>

                    <td className="text-center">
                      {' '}
                      <div>
                        <label>0 </label>
                      </div>
                    </td>

                    {Object.keys(weeksPerMonthList).map((month) => {
                      return (
                        <td className="month">
                          <div className="weeks-container">
                            {weeksPerMonthList[month].map((week) => {
                              return (
                                <td>
                                  <div>
                                    <label>{0}</label>
                                  </div>
                                </td>
                              );
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </CardContainer>
  );
};
