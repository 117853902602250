import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UserIcon,
  UpdateSharedCandidatesList,
  MultipleSelect,
  Pagination,
  BackAction,
} from '../../components';
import { EditIcon, EyeIcon, SortIcon } from '../../icons';
import {
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterCandidate,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Candidate, CandidatesList, User } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';
import { CandidateDetailsContainer } from '../candidate/candidate-details/candidate-details.style';
import { useModal } from '../../contexts/side-modal.context';

const itemsPerPage = 8;

export const SharedCandidatesDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const { openModal, closeModal } = useModal();

  const [candidateListDetails, setCandidateListDetails] =
    useState<CandidatesList | null>(null);
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    grades: [],
    levels: [],
    status: [],
    completions: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);

  const onClickEdit = (candidateList: CandidatesList) => {
    openModal({
      title: 'Update List',
      component: (
        <UpdateSharedCandidatesList
          onCancel={() => closeModal()}
          onSuccess={() => {
            getSharedListDetails();
            closeModal();
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidateList={candidateList}
          data-testid="update-candidate-list-form"
        />
      ),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CANDIDATES}/${id}`);
  };

  const getSharedListDetails = async () => {
    AxiosInstance.candidatesLists
      .candidatesListsControllerFindOne(id!)
      .then((response) => {
        setCandidateListDetails(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, itemsPerPage);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(
      candidateListDetails?.candidates ?? [],
      filter,
    );
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData);
    setCurrentPage(1);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidateListDetails, filter, sort]);

  useEffect(() => {
    getSharedListDetails();
    getCandidates();
    getUsers();
  }, []);

  if (!candidateListDetails) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer data-testid="candidate-details-container">
        <TabFilter
          filters={[
            {
              title: 'Candidates',
              url: ERoute.CANDIDATES,
            },
            {
              title: 'Lists',
              url: ERoute.SHARED_CANDIDATES,
            },
          ]}
          data-testid="tab-filter"
        />
        <BackAction data-testid="back-action" />
        <CardContainer data-testid="card-container">
          <HeaderPageContainer data-testid="header-page-container">
            <div className="left-container" data-testid="left-container">
              <TitlePage data-testid="candidate-list-title">
                {candidateListDetails.name}
              </TitlePage>
              <SubTitlePage data-testid="candidate-list-subtitle">
                Manage your List
              </SubTitlePage>
            </div>
            <div className="right-container" data-testid="right-container">
              <Button
                type="primary"
                onClick={() => onClickEdit(candidateListDetails)}
                disabled={user?._id !== candidateListDetails.user._id}
                data-testid="edit-button"
              >
                <EditIcon data-testid="edit-icon" /> Edit List
              </Button>
            </div>
          </HeaderPageContainer>
          <div className="filter-container" data-testid="filter-container">
            <SearchInput
              placeholder="Search candidate"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
              }
              data-testid="search-input"
            />
            <MultipleSelect
              placeholder="All Regions"
              options={regionsWithCounties.map((el) => ({
                label: el.region,
                value: el.region,
              }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  regions: values ?? [],
                }));
              }}
              data-testid="region-multiselect"
            />
            <MultipleSelect
              placeholder="All Counties"
              options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
                (el) => ({ label: el, value: el }),
              )}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  counties: values ?? [],
                }));
              }}
              disabled={!filter.regions.length && !filter.counties.length}
              data-testid="county-multiselect"
            />
            <MultipleSelect
              placeholder="All Levels"
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({ ...prev, levels: values ?? [] }));
              }}
              options={jobLevelWithGrades.map((el) => ({
                label: el.level,
                value: el.level,
              }))}
              data-testid="level-multiselect"
            />
            <MultipleSelect
              placeholder="All Grades"
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({ ...prev, grades: values ?? [] }));
              }}
              options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
                label: el,
                value: el,
              }))}
              disabled={!filter.levels.length && !filter.grades.length}
              data-testid="grade-multiselect"
            />
          </div>
          <div
            className="data-table-container"
            data-testid="data-table-container"
          >
            <Table data-testid="candidate-table">
              <thead>
                <tr>
                  <th className="checkbox-table" data-testid="checkbox-header">
                    <input type="checkbox" />
                  </th>
                  <th
                    onClick={() => onSelectSort('firstName', setSort)}
                    data-testid="sort-name"
                  >
                    <div>
                      <label>Name </label>
                      <SortIcon
                        value={sort.key === 'firstName' ? sort.value : ''}
                        data-testid="sort-icon-name"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('designation', setSort)}
                    data-testid="sort-job-title"
                  >
                    <div>
                      <label>Job title</label>
                      <SortIcon
                        value={sort.key === 'designation' ? sort.value : ''}
                        data-testid="sort-icon-job-title"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('grade', setSort)}
                    data-testid="sort-grade"
                  >
                    <div>
                      <label>NHS Pay Grade</label>
                      <SortIcon
                        value={sort.key === 'grade' ? sort.value : ''}
                        data-testid="sort-icon-grade"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('user', setSort)}
                    data-testid="sort-line-manager"
                  >
                    <div>
                      <label>Line Manager</label>
                      <SortIcon
                        value={sort.key === 'user' ? sort.value : ''}
                        data-testid="sort-icon-line-manager"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('county', setSort)}
                    data-testid="sort-county"
                  >
                    <div>
                      <label>County</label>
                      <SortIcon
                        value={sort.key === 'county' ? sort.value : ''}
                        data-testid="sort-icon-county"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('region', setSort)}
                    data-testid="sort-region"
                  >
                    <div>
                      <label>Region</label>
                      <SortIcon
                        value={sort.key === 'region' ? sort.value : ''}
                        data-testid="sort-icon-region"
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => onSelectSort('status', setSort)}
                    data-testid="sort-status"
                  >
                    <div>
                      <label>Status</label>
                      <SortIcon
                        value={sort.key === 'status' ? sort.value : ''}
                        data-testid="sort-icon-status"
                      />
                    </div>
                  </th>
                  <th data-testid="actions-column">
                    <div></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((candidate) => {
                  return (
                    <tr
                      key={candidate._id}
                      data-testid={`candidate-row-${candidate._id}`}
                    >
                      <td
                        className="checkbox-table"
                        data-testid="checkbox-cell"
                      >
                        <input type="checkbox" />
                      </td>
                      <td data-testid="name-cell">
                        <div className="name-item" data-testid="name-item">
                          <UserIcon
                            firstName={candidate.firstName}
                            lastName={candidate.lastName}
                            entity="candidate"
                            data-testid="user-icon"
                          />
                          <div>
                            <div>
                              {candidate.firstName} {candidate.lastName}
                            </div>
                            <div
                              className="email"
                              data-testid="candidate-email"
                            >
                              {candidate.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td data-testid="job-title-cell">
                        <div className="name-item" data-testid="job-title-item">
                          <div>
                            <div> {candidate?.designation || '-'}</div>
                            <div className="email" data-testid="department">
                              {candidate.departments?.join(',') || '-'}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td data-testid="grade-cell">{candidate?.grade}</td>
                      <td data-testid="line-manager-cell">
                        {candidate?.recruiter?.firstName}{' '}
                        {candidate?.recruiter?.lastName}
                      </td>
                      <td data-testid="county-cell">
                        {candidate.address?.county || '-'}{' '}
                      </td>
                      <td data-testid="region-cell">
                        {candidate.address?.region || '-'}{' '}
                      </td>
                      <td data-testid="status-cell">
                        <Badge type={'success'} data-testid="active-badge">
                          Active
                        </Badge>
                      </td>
                      <td data-testid="actions-cell">
                        <div className="action-item" data-testid="action-item">
                          <div
                            className="view-icon"
                            onClick={() => onClickView(candidate._id)}
                            data-testid={`view-icon-${candidate._id}`}
                          >
                            <EyeIcon data-testid="eye-icon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalEntries={candidatesList.length}
            data-testid="pagination"
          />
        </CardContainer>
      </CandidateDetailsContainer>
    </>
  );
};
