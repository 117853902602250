import React from 'react';
import { UserIcon } from '../UserIcon';
import { Application } from '../../../backend/careo-api';
import {
  AttachFileIcon,
  CancelIcon,
  DotsIcon,
  Message2Icon,
} from '../../../icons';
import { ERoute } from '../../../utils';
import { handleDragStart } from './pipeline';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';

const ApplicationCardPipelineContainer = styled.div`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  .card-item-content {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 20px;
    .card-item-info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .card-item-info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 14px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .close-icon {
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .email {
        color: #a0aec0;
        font-size: 12px;
        font-weight: 400;
      }
      .date {
        color: #a0aec0;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  .card-item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .card-item-left-footer {
      display: flex;
      align-items: center;
      gap: 20px;
      color: #a0aec0;
      svg {
        margin-right: 5px;
        path {
          fill: #a0aec0;
        }
      }
    }
    .card-item-right-footer {
      cursor: pointer;
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 5px;
    .card-item-content {
      gap: 5px;
      margin-bottom: 10px;
      .card-item-info {
        .name {
          font-size: 10px;
        }
        .email {
          font-size: 8px;
        }
        .date {
          font-size: 8px;
        }
      }
    }
    .card-item-footer {
      .card-item-left-footer {
        gap: 10px;
        svg {
          margin-right: 2px;
        }
      }
    }
  }
`;

export const ApplicationCardPipeline = ({
  item,
  listIndex,
  itemIndex,
  onClickDeleteApplication,
  onClickEdit,
}: {
  item: Application;
  listIndex: number;
  itemIndex: number;
  onClickDeleteApplication?: (application: Application) => void;
  onClickEdit: (application: Application) => void;
}) => {
  const onClickNavigateToCandidateProfile = (id: string) => {
    const url = `/${ERoute.CANDIDATES}/${id}`;
    window.open(url, '_blank');
  };

  return (
    <ApplicationCardPipelineContainer
      key={item._id}
      draggable="true"
      onDragStart={(e) => handleDragStart(e, listIndex, itemIndex)}
      onDoubleClick={() =>
        onClickNavigateToCandidateProfile(item.candidate?._id ?? '')
      }
      data-testid="application-card-container"
    >
      <div className="card-item-content" data-testid="card-item-content">
        <UserIcon
          firstName={item.candidate?.firstName ?? ''}
          lastName={item.candidate?.lastName ?? ''}
          entity="candidate"
          data-testid="user-icon"
        />
        <div className="card-item-info" data-testid="card-item-info">
          <div
            className="card-item-info-header"
            data-testid="card-item-info-header"
          >
            <div className="name" data-testid="candidate-name">
              {item.candidate?.firstName} {item.candidate?.lastName}
            </div>
            {onClickDeleteApplication && (
              <div
                className="close-icon"
                onClick={() => onClickDeleteApplication(item)}
                data-testid="delete-application-btn"
              >
                <CancelIcon />
              </div>
            )}
          </div>
          <div className="email" data-testid="candidate-email">
            {item.candidate?.email}
          </div>
          <div className="email" data-testid="candidate-phone">
            {item.candidate?.phoneNumber}
          </div>
        </div>
      </div>
      <div className="card-item-footer" data-testid="card-item-footer">
        <div
          className="card-item-left-footer"
          data-testid="card-item-left-footer"
        >
          <Message2Icon /> 0
          <AttachFileIcon /> 0
        </div>
        <div
          className="card-item-right-footer"
          onClick={() => onClickEdit(item)}
          data-testid="edit-application-btn"
        >
          <DotsIcon />
        </div>
      </div>
    </ApplicationCardPipelineContainer>
  );
};
