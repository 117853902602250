import React, { useEffect, useState } from 'react';
import { FieldText, Button, RadioGroup, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { AddAvailabilityDto, EAiJobShift } from '../../backend/careo-api';
import {
  AvailabilitySchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../utils';

type NewAvailabilityFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewAvailabilityForm = ({
  onCancel,
  onSuccess,
}: NewAvailabilityFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<AddAvailabilityDto & { typeDuration: 'single' | 'multiple' }>({
    resolver: yupResolver(AvailabilitySchema as any),
  });

  const [typeDuration, setTypeDuration] = useState<'single' | 'multiple'>(
    'single',
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.availability
      .availabilityControllerAddAvailability({
        ...values,
        to: typeDuration === 'single' ? undefined : values.to,
        candidateId: id!,
      })
      .then(() => {
        toast.success('Availability added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      setValue('candidateId', id);
      setValue('typeDuration', 'single');
    } else {
      toast.error('Please select a candidate to create availability for.');
    }

    setValue('availabilityTime', EAiJobShift.Day);
  }, [id]);

  return (
    <FormContainer data-testid="form-container">
      <RadioGroup
        name="Availability Type"
        options={[
          {
            label: 'Single Day',
            value: 'single',
          },
          {
            label: 'Multiple Day',
            value: 'multiple',
          },
        ]}
        onChange={(value) => {
          setValue('typeDuration', value as any);
          setTypeDuration(value as any);
        }}
        value={typeDuration}
        error={errors.typeDuration}
        data-testid="availability-type-radio-group"
      />
      {/* <RadioGroup
        name="Availability Time"
        options={availabilityTimeListOption}
        register={register('availabilityTime', { required: true })}
        error={errors.availabilityTime}
        data-testid="availability-time-radio-group"
      /> */}
      <div className="row" data-testid="date-fields-row">
        <div className="col-md-6" data-testid={`timings-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="Timings"
            required
            value={'08:00'}
            type="time"
            disabled
          />
        </div>
        <div className="col-md-6" data-testid={`empty-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="&nbsp;"
            value={'16:00'}
            type="time"
            disabled
          />
        </div>
        <div
          className={`col-md-${typeDuration === 'multiple' ? 6 : 12}`}
          data-testid="from-date-container"
        >
          <FieldText
            placeholder="Enter here ..."
            label={typeDuration === 'multiple' ? 'From' : 'Date'}
            required
            type="date"
            register={register('from', { required: true })}
            error={errors.from}
            min={new Date().toISOString().split('T')[0]}
            data-testid="from-date-field"
          />
        </div>
        {typeDuration === 'multiple' && (
          <div className="col-md-6" data-testid="to-date-container">
            <FieldText
              placeholder="Enter here ..."
              label="To"
              required
              type="date"
              register={register('to', { required: true })}
              error={errors.to}
              min={new Date().toISOString().split('T')[0]}
              data-testid="to-date-field"
            />
          </div>
        )}
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
