import React, { useEffect, useMemo, useState } from 'react';
import {
  Project,
  ProjectFinancialDataResultDto,
} from '../../backend/careo-api';
import { RevenueChart } from './charts/revenue-chart';
import { Abbreviation, Badge, InfoCardRow } from '../ui';
import { AxiosInstance } from '../../utils';
import { ProgressBarCustom } from '../ui/progress-bar-cutom';

type ProjectDetailsFinancialProps = {
  project: Project;
};

export const ProjectDetailsFinancial = ({
  project,
}: ProjectDetailsFinancialProps) => {
  const [financialData, setFinancialData] =
    useState<ProjectFinancialDataResultDto | null>(null);

  useEffect(() => {
    const fetchFinancialData = async () => {
      const data = await AxiosInstance.projects
        .projectsControllerGetFinancialDataForProject(project._id)
        .then((res) => res.data);
      setFinancialData(data);
    };

    fetchFinancialData();
  }, [project._id]);

  const calculatePercentage = (actual: number, forecast: number) =>
    forecast ? Number((actual / forecast) * 100) : 0;

  const projectElapsedTime = useMemo(() => {
    if (project.activities.length === 0) return null;

    const startDates = project.activities.map((activity) =>
      new Date(activity.startDate).getTime(),
    );
    const endDates = project.activities.map((activity) =>
      new Date(activity.endDate).getTime(),
    );

    const projectStartDate = new Date(Math.min(...startDates));
    const projectEndDate = new Date(Math.max(...endDates));
    const currentDate = new Date();

    const totalDuration = projectEndDate.getTime() - projectStartDate.getTime();
    const elapsedDuration = currentDate.getTime() - projectStartDate.getTime();
    const elapsedDays = Math.floor(elapsedDuration / (1000 * 60 * 60 * 24));
    const percentageElapsed = totalDuration
      ? Math.min((elapsedDuration / totalDuration) * 100, 100)
      : 0;

    return {
      startDate: projectStartDate,
      endDate: projectEndDate,
      totalDays: Math.floor(totalDuration / (1000 * 60 * 60 * 24)),
      elapsedDays,
      percentageElapsed,
    };
  }, [project.activities]);

  return (
    <>
      <div className="info-card" data-testid="financials-card">
        <div className="info-card-title" data-testid="financials-title">
          Financials
        </div>

        <div>
          <div>
            <div className="info-card-title" data-testid="budget-title"></div>
            <div className="numbers" data-testid="budget-numbers">
              <span className="green">
                {projectElapsedTime?.elapsedDays ?? 0} Days
              </span>{' '}
              / {projectElapsedTime?.totalDays} Days
            </div>
            <ProgressBarCustom
              value={projectElapsedTime?.percentageElapsed ?? 0}
              data-testid="progress-bar-1"
            />
          </div>
        </div>
        <hr />
        <div className="info-card-content" data-testid="financials-content">
          <div className="row">
            <div className="col-lg-4" data-testid="revenue-chart">
              <RevenueChart
                isLoading={!financialData}
                chartData={[
                  {
                    label: 'Revenue',
                    backgroundColor: '#11af60',
                    borderColor: '#11af60',
                    value: financialData?.totalRevenue ?? 0,
                    percentage: calculatePercentage(
                      financialData?.totalRevenue ?? 0,
                      financialData?.totalForecastRevenue ?? 0,
                    ),
                  },
                  {
                    label: 'Forecasted Revenue',
                    backgroundColor: '#FFD700', // Changed to a meaningful color
                    borderColor: '#FFD700', // Changed to a meaningful color
                    value: financialData?.totalForecastRevenue ?? 0,
                    percentage:
                      100 -
                      calculatePercentage(
                        financialData?.totalRevenue ?? 0,
                        financialData?.totalForecastRevenue ?? 0,
                      ),
                  },
                ]}
                data-testid="revenue-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="cost-chart">
              <RevenueChart
                isLoading={!financialData}
                chartData={[
                  {
                    label: 'Cost',
                    backgroundColor: '#FF4500', // Changed to a meaningful color
                    borderColor: '#FF4500', // Changed to a meaningful color
                    value: financialData?.totalCharge ?? 0,
                    percentage: calculatePercentage(
                      financialData?.totalCharge ?? 0,
                      financialData?.totalForecastCharge ?? 0,
                    ),
                  },
                  {
                    label: 'Forecasted Cost',
                    backgroundColor: '#FFA500', // Changed to a meaningful color
                    borderColor: '#FFA500', // Changed to a meaningful color
                    value: financialData?.totalForecastCharge ?? 0,
                    percentage:
                      100 -
                      calculatePercentage(
                        financialData?.totalCharge ?? 0,
                        financialData?.totalForecastCharge ?? 0,
                      ),
                  },
                ]}
                data-testid="cost-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="margin-chart">
              <RevenueChart
                isLoading={!financialData}
                chartData={[
                  {
                    label: 'Margin',
                    backgroundColor: '#1E90FF', // Changed to a meaningful color
                    borderColor: '#1E90FF', // Changed to a meaningful color
                    value: financialData?.grossMargin ?? 0,
                    percentage: calculatePercentage(
                      financialData?.grossMargin ?? 0,
                      financialData?.totalForecastMargin ?? 0,
                    ),
                  },
                  {
                    label: 'Forecasted Margin',
                    backgroundColor: '#87CEFA', // Changed to a meaningful color
                    borderColor: '#87CEFA', // Changed to a meaningful color
                    value: financialData?.totalForecastMargin ?? 0,
                    percentage:
                      100 -
                      calculatePercentage(
                        financialData?.grossMargin ?? 0,
                        financialData?.totalForecastMargin ?? 0,
                      ),
                  },
                ]}
                data-testid="margin-chart-component"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="info-card" data-testid="invoicing-card">
        <div className="info-card-title" data-testid="invoicing-title">
          Invoicing
        </div>
        <hr />
        <div className="row" data-testid="invoicing-row">
          <div className="col-md-6" data-testid="client-info-col">
            <div className="info-card-content row" data-testid="client-info">
              <InfoCardRow
                title="Contact"
                data={
                  project.contact ? (
                    <Badge type="neutral">
                      <Abbreviation>
                        {project.contact.firstName[0]}
                        {project.contact.lastName[0]}
                      </Abbreviation>
                      {project.contact.firstName} {project.contact.lastName}
                    </Badge>
                  ) : null
                }
                dataTestId="client-name"
              />
              <InfoCardRow
                title="Staff Pay Frequency"
                data={project.staffPayFrequency}
                dataTestId="staffPayFrequency"
              />
              <InfoCardRow
                title="Payment Terms"
                data={project.paymentTerms}
                dataTestId="hospital"
              />
            </div>
          </div>
          <div className="col-md-6" data-testid="service-info-col">
            <div className="info-card-content row" data-testid="service-info">
              <InfoCardRow
                title="Invoicing To"
                data={project.invoicingTo}
                dataTestId="cpm"
              />
              <InfoCardRow
                title="CC:"
                data={project.invoicingCC}
                dataTestId="service-coordinator"
              />
              <InfoCardRow
                title="Invoice Frequency"
                data={project.invoiceFrequency}
                dataTestId="cem"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
