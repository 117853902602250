import React, { ReactNode } from 'react';
import { RadioGroupComponent } from './radio-group.style';
import { FieldError } from 'react-hook-form';

type RadioGroupProps = {
  options: {
    label: string;
    value: string | number | boolean;
    description?: ReactNode;
    disabled?: boolean;
  }[];
  name: string;
  className?: string;
  containerClassName?: string;
  onChange?: (value: string | boolean | number) => any;
  value?: string | boolean | number;
  register?: any;
  error?: FieldError;
};

export const RadioGroup = ({
  options,
  name,
  className,
  containerClassName,
  register,
  onChange,
  value,
  error,
}: RadioGroupProps) => {
  return (
    <RadioGroupComponent hasError={!!error} data-testid="radio-group">
      <div
        className={`options-container ${containerClassName ?? ''}`}
        data-testid="radio-options-container"
      >
        {options.map((el, index) => (
          <div
            className={`option-container ${className ?? ''}`}
            key={index}
            data-testid={`radio-option-${index}`}
          >
            <input
              type="radio"
              name={name}
              id={`${name}-${index}`}
              value={el.value}
              {...(value && { checked: value === el.value })}
              {...register}
              {...(onChange && {
                onChange: (e) => {
                  onChange(e.target.value);
                },
              })}
              disabled={el.disabled}
              data-testid={`radio-input-${index}`}
            />
            <label
              htmlFor={`${name}-${index}`}
              className="option"
              data-testid={`radio-label-${index}`}
            >
              <div className="label-container">
                <div className="title-radio">{el.label}</div>
                {el.description && (
                  <>
                    <br />
                    <div className="description-radio">{el.description}</div>
                  </>
                )}
              </div>
              <div className="dot"></div>
            </label>
          </div>
        ))}
      </div>
      {error && (
        <span className="error-container" data-testid="radio-error">
          {error?.message}
        </span>
      )}
    </RadioGroupComponent>
  );
};
