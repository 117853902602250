import * as yup from 'yup';
import dayjs from 'dayjs';
import {
  EJobLevel,
  EJobShift,
  ENhsPayGrade,
  ESpecialty,
} from '../../backend/careo-api';

export const AddCandidateVacancySchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  durations: yup.array().of(
    yup.object().shape({
      availableFrom: yup
        .string()
        .required('Start date is required')
        .test(
          'availableFrom-availableTo-valid',
          'Start date must be before End date',
          function (value) {
            const availableToValue = this.parent.availableTo;
            if (
              availableToValue &&
              dayjs(value).isAfter(dayjs(availableToValue))
            ) {
              return false;
            }
            return true;
          },
        ),
      availableTo: yup
        .string()
        .required('End date is required')
        .when('availableFrom', (availableFromValue, schema) => {
          return availableFromValue
            ? schema.test(
                'availableTo-availableFrom-valid',
                'End date must be after Start date',
                function (value) {
                  const availableFromValue = this.parent.availableFrom;
                  if (
                    value &&
                    dayjs(value).isBefore(dayjs(availableFromValue))
                  ) {
                    return false;
                  }
                  return true;
                },
              )
            : schema;
        }),
    }),
  ),
  candidateId: yup.string().required('Candidate is required'),
});

export const AddCandidateRoleSchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  candidateId: yup.string().required('Candidate is required'),
});

export const UpdateCandidateVacancySchema = yup.object().shape({
  durations: yup.array().of(
    yup.object().shape({
      availableFrom: yup
        .string()
        .required('Start date is required')
        .test(
          'availableFrom-availableTo-valid',
          'Start date must be before End date',
          function (value) {
            const availableToValue = this.parent.availableTo;
            if (
              availableToValue &&
              dayjs(value).isAfter(dayjs(availableToValue))
            ) {
              return false;
            }
            return true;
          },
        ),
      availableTo: yup
        .string()
        .required('End date is required')
        .when('availableFrom', (availableFromValue, schema) => {
          return availableFromValue
            ? schema.test(
                'availableTo-availableFrom-valid',
                'End date must be after Start date',
                function (value) {
                  const availableFromValue = this.parent.availableFrom;
                  if (
                    value &&
                    dayjs(value).isBefore(dayjs(availableFromValue))
                  ) {
                    return false;
                  }
                  return true;
                },
              )
            : schema;
        }),
    }),
  ),
});

export const UpdateVacancySchema = yup.object().shape({
  availableFrom: yup
    .string()
    .required('Start date is required')
    .test(
      'availableFrom-availableTo-valid',
      'Start date must be before End date',
      function (value) {
        const availableToValue = this.parent.availableTo;
        if (availableToValue && dayjs(value).isAfter(dayjs(availableToValue))) {
          return false;
        }
        return true;
      },
    ),
  availableTo: yup
    .string()
    .required('End date is required')

    .when('availableFrom', (availableFromValue, schema) => {
      return availableFromValue
        ? schema.test(
            'availableTo-availableFrom-valid',
            'End date must be after Start date',
            function (value) {
              const availableFromValue = this.parent.availableFrom;
              if (value && dayjs(value).isBefore(dayjs(availableFromValue))) {
                return false;
              }
              return true;
            },
          )
        : schema;
    }),
});

export const vacancySchema = yup.object().shape({
  shift: yup
    .string()
    .required('Shift is required.')
    .oneOf(Object.values(EJobShift), 'Invalid Shift.'),
  grade: yup
    .string()
    .required('Grade is required.')
    .oneOf(Object.values(ENhsPayGrade), 'Invalid Grade.'),
  level: yup
    .string()
    .required('Job Title is required.')
    .oneOf(Object.values(EJobLevel), 'Invalid Job Title.'),
  specialty: yup
    .string()
    .required('Specialty is required.')
    .oneOf(Object.values(ESpecialty), 'Invalid Specialty.'),
  jobDescription: yup.string().required('Job description is required.'),
  status: yup.string().required('Status is required'),
  vacantFrom: yup
    .string()
    .required('Start date is required')
    .test(
      'vacantFrom-vacantTo-valid',
      'Start date must be before Vacant To date',
      function (value) {
        const vacantToValue = this.parent.vacantTo;
        if (vacantToValue && dayjs(value).isAfter(dayjs(vacantToValue))) {
          return false;
        }
        return true;
      },
    ),
  vacantTo: yup
    .string()
    .required('End date is required')
    .when('vacantFrom', (vacantFromValue, schema) => {
      return vacantFromValue
        ? schema.test(
            'vacantTo-vacantFrom-valid',
            'End date must be after Vacant From date',
            function (value) {
              const vacantFromValue = this.parent.vacantFrom;
              if (value && dayjs(value).isBefore(dayjs(vacantFromValue))) {
                return false;
              }
              return true;
            },
          )
        : schema;
    }),
  userId: yup.string().required('Recruiter  is required.'),
  clientId: yup.string().required('Client  is required.'),

  charge: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Charge must be a valid number.')
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .positive('Charge must be a positive number')
    .required('Charge is required')
    .test(
      'charge-must-be-greater-than-rate',
      'Charge amount must be greater than Rate Amount',
      function (value) {
        return this.parent.rate >= value ? false : true;
      },
    ),

  rate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Rate must be a valid number.')
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .positive('Rate must be a positive number')
    .test(
      'rate-must-be-less-than-charge',
      'Rate amount must be smaller than Charge  Amount',
      function (value) {
        return value && this.parent.charge <= value ? false : true;
      },
    ),

  vat: yup
    .string()
    .required('VAT percentage is required.')
    .matches(/^\d+(\.\d+)?$/, 'Invalid VAT percentage format.'),
});
