import React, { useEffect, useState } from 'react';
import { SelectContainer } from './select.style';
import { Controller, FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { Badge } from '../../badge';

type OptionType = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type FieldTextProps = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: FieldError;
  onClick?: () => void;
  register?: UseFormRegisterReturn<any>;
  onChange?: (value: (string | number)[]) => void;
  options: OptionType[];
  value?: string[] | number[];
  isWithTags?: boolean;
  control?: any;
  'data-testid'?: string;
};

export const MultipleSelect = ({
  label,
  value,
  onChange,
  required,
  options,
  register,
  className,
  disabled,
  error,
  placeholder,
  onClick,
  isWithTags,
  control,
  'data-testid': dataTestId,
}: FieldTextProps) => {
  const [selectedValues, setSelectedValues] = useState<OptionType[]>([]);

  const handleChange = (newValues: OptionType[]) => {
    setSelectedValues(newValues);
    const values = newValues.map((el) => el.value);
    onChange?.(values);
    register?.onChange({
      target: {
        value: values,
        name: register?.name,
      },
    });
  };

  const renderAutocomplete = (autoValues: OptionType[]) => {
    return (
      <Autocomplete
        data-testid={dataTestId}
        disablePortal
        options={options}
        limitTags={2}
        getOptionLabel={(option) => option.label}
        multiple
        value={autoValues}
        disabled={disabled}
        onChange={(_, newValues) => {
          handleChange(newValues as OptionType[]);
        }}
        renderInput={(params) => (
          <TextField
            className="mui-input"
            {...params}
            placeholder={placeholder}
            data-testid="autocomplete-input" // Test ID for the input field
          />
        )}
        renderTags={
          isWithTags
            ? (values) =>
                values.map((el) => (
                  <Badge
                    type="success"
                    key={el.value}
                    data-testid={`badge-tag-${el.value}`}
                  >
                    {el.label}
                  </Badge>
                ))
            : undefined
        }
        renderOption={(props, option) => {
          const index = selectedValues.findIndex(
            (el) => el.value === option.value,
          );
          const alreadyExists = index > -1;
          const onClick = () => {
            const newValues = [...selectedValues];
            if (alreadyExists) {
              newValues.splice(index, 1);
            } else {
              newValues.push(option);
            }
            handleChange(newValues);
          };
          return (
            <li
              {...props}
              aria-selected={alreadyExists}
              onClick={onClick}
              data-testid={`autocomplete-option-${option.value}`} // Test ID for each option
            >
              {option.label}
            </li>
          );
        }}
      />
    );
  };

  useEffect(() => {
    if (!selectedValues.length && value) {
      setSelectedValues(
        value
          .map((el) => options.find((op) => op.value === el)!)
          .filter((el) => el),
      );
    }
  }, [options, value, disabled]);

  return (
    <SelectContainer
      className={`select-container ${className ?? ''} ${
        isWithTags && 'with-tags'
      }`}
      hasError={!!error}
      onClick={() => onClick?.()}
      data-testid="select-container" // Test ID for the container
    >
      {label && (
        <label htmlFor="" data-testid="select-label">
          {label} {required && <span>*</span>}
        </label>
      )}

      {control ? (
        <Controller
          control={control}
          name={register?.name ?? ''}
          render={({
            field: { onChange: fieldOnChange, value: values = [] },
          }) => {
            const defaultValues = values
              .map((el: string | number) =>
                options.find((op) => op.value === el),
              )
              .filter(Boolean) as OptionType[];
            return renderAutocomplete(defaultValues);
          }}
        />
      ) : (
        renderAutocomplete(selectedValues)
      )}

      {error && (
        <span className="error-container" data-testid="select-error">
          {error?.message}
        </span>
      )}
    </SelectContainer>
  );
};
