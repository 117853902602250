import * as yup from 'yup';
import { regex } from '../regex.utils';
import {
  ELeadDurationType,
  ELeadType,
  ESpecialty,
} from '../../backend/careo-api';

const generalLeadSchema = yup.object().shape({
  specialty: yup
    .string()
    .oneOf(Object.values(ESpecialty), 'Invalid specialty')
    .required('Specialty is required'),

  type: yup
    .string()
    .oneOf(Object.values(ELeadType), 'Invalid type')
    .required('Type is required'),

  cpmId: yup.string().required('CPM ID is required'),

  region: yup
    .string()
    .required('Region is required')
    .matches(regex.name, 'Region is not valid'),

  trustId: yup.string(),

  hospitalId: yup.string().required('Hospital ID is required'),
  clientDepartmentId: yup.string().required('Contact ID is required'),
});

const financialLeadSchema = yup.object().shape({
  revenue: yup
    .number()
    .required('Revenue is required')
    .typeError('Revenue must be a number'),

  cost: yup
    .number()
    .required('Cost is required')
    .typeError('Cost must be a number'),

  durationValue: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value,
    )
    .positive('Duration value must be a positive number')
    .required('Duration value is required'),

  durationType: yup
    .string()
    .oneOf(Object.values(ELeadDurationType), 'Invalid duration type')
    .required('Duration type is required'),

  startDate: yup
    .string()
    .required('Start date is required')
    .test(
      'is-date',
      'Invalid date format',
      (value) => !value || !isNaN(Date.parse(value)),
    ),
});

const legalLeadSchema = yup.object().shape({
  legalContactId: yup.string().required('Legal contact is required'),
});

export { generalLeadSchema, financialLeadSchema, legalLeadSchema };
