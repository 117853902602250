import React, { useEffect, useMemo, useState } from 'react';
import {
  Application,
  Candidate,
  EProjectActivityType,
  Project,
} from '../../backend/careo-api';
import { ColumnSwitch, PlusIcon, RowSwitch } from '../../icons';
import { Button, DropdownSelectText, MultipleSelect, SearchInput } from '../ui';
import { WeekData, WeeksSlider } from './rotas-components/weeks-slider';
import { RotasWeekCalendar } from './rotas-components/rotas-week-calendar';
import { monthsList } from '../../constants';
import { ProjectPlacementsList } from './rotas-list';

type ProjectDetailsRotasProps = {
  project: Project;
  candidates: Candidate[];
  placements: Application[];
  getPlacements: () => void;
  getProjectDetails: () => void;
};

export type RotasItem = {
  _id: string;
  day: Date;
  activityType: EProjectActivityType;
  role: string;
  status: string;
  workerName: string;
  rate: number;
};

const generateWeeks = (year: number): WeekData[] => {
  const weeksList: WeekData[] = [];
  const startDate = new Date(year, 0, 1);
  let index = 1;

  while (startDate.getFullYear() === year) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const label = `${startDate.getDate()}-${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}`;

    weeksList.push({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      label: label,
      week: index,
      month: startDate.getMonth(),
      year: startDate.getFullYear(),
    });

    startDate.setDate(startDate.getDate() + 7); // Move to next week
    index++;
  }

  return weeksList;
};

const getCurrentWeek = (): WeekData => {
  const today = new Date();
  const firstDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay()),
  );
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear =
    (today.getTime() - firstDayOfYear.getTime()) / (1000 * 60 * 60 * 24);
  const week = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

  return {
    startDate: firstDayOfWeek,
    endDate: lastDayOfWeek,
    label: `${firstDayOfWeek.toLocaleDateString()} - ${lastDayOfWeek.toLocaleDateString()}`,
    week,
    month: firstDayOfWeek.getMonth(),
    year: firstDayOfWeek.getFullYear(),
  };
};

const YEAR_OPTIONS = Array.from({ length: 7 }, (_, i) => {
  const year = 2020 + i;
  return { label: year.toString(), value: year };
});

export const ProjectDetailsRotas = ({
  project,
  candidates,
  placements,
  getPlacements,
  getProjectDetails,
}: ProjectDetailsRotasProps) => {
  const [isRowSwitch, setIsRowSwitch] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState<WeekData>();
  const [weeksList, setWeeksList] = useState<WeekData[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear(),
  );

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const generatedWeeks = generateWeeks(selectedYear);
    setWeeksList(generatedWeeks);
    if (selectedMonth === currentMonth && selectedYear === currentYear) {
      const currentWeek = getCurrentWeek();
      setSelectedWeek(currentWeek);
    } else {
      setSelectedWeek(
        generatedWeeks.find(
          (el) => el.month === selectedMonth && el.year === selectedYear,
        ),
      );
    }
  }, [selectedYear, selectedMonth]);

  const rotasList = useMemo(() => {
    if (!project || !placements || !selectedWeek) {
      return [];
    }

    return placements
      .filter((placement) => {
        const placementDate = new Date(placement.availableFrom).setHours(
          12,
          0,
          0,
          0,
        );
        const startWeek = new Date(selectedWeek.startDate).setHours(0, 0, 0, 0);
        const endWeek = new Date(selectedWeek.endDate).setHours(
          23,
          59,
          59,
          999,
        );

        return placementDate >= startWeek && placementDate <= endWeek;
      })
      .map((el) => {
        const activities = project.activities.map((activity) => {
          const matchingRoles = activity.roles.filter(
            (role) => role._id === el.job._id,
          );
          return {
            ...activity,
            placements: matchingRoles,
          };
        });

        return activities.map((activity) => ({
          _id: el._id,
          day: new Date(el.availableFrom),
          activityType: activity.activityType,
          role: `${activity.roles.find((role) => role._id === el.job._id)?.level || ''} (${activity.roles.find((role) => role._id === el.job._id)?.grade || ''})`,
          status: el.approvalStatus || 'Pending',
          workerName: `${el.candidate?.firstName || 'Unassigned'} ${el.candidate?.lastName || ''}`,
          rate: activity.rate || 0,
        }));
      })
      .flat();
  }, [project, placements, selectedWeek]);

  return (
    <div className="rotas-list-content" data-testid="rotas-list-content">
      <div
        className="rotas-header-container"
        data-testid="rotas-header-container"
      >
        <div
          className="rotas-header-left-container"
          data-testid="rotas-header-left"
        >
          <DropdownSelectText
            items={monthsList}
            selectedItem={selectedMonth}
            setSelectedItem={setSelectedMonth}
            data-testid="time-period-dropdown"
          />
          <DropdownSelectText
            items={YEAR_OPTIONS}
            selectedItem={selectedYear}
            setSelectedItem={setSelectedYear}
            data-testid="time-period-dropdown"
          />
        </div>
        <div
          className="rotas-header-right-container"
          data-testid="rotas-header-right"
        >
          <Button
            type="success"
            variant="outlined"
            data-testid="generate-rota-button"
            disabled
          >
            <PlusIcon /> Generate Rota with AI
          </Button>
          {isRowSwitch ? (
            <RowSwitch
              onClick={() => setIsRowSwitch(false)}
              data-testid="row-switch"
            />
          ) : (
            <ColumnSwitch
              onClick={() => setIsRowSwitch(true)}
              data-testid="column-switch"
            />
          )}
        </div>
      </div>
      <div
        className="rotas-filter-container"
        data-testid="rotas-filter-container"
      >
        <SearchInput
          placeholder="Search candidates"
          onChange={() => {}}
          data-testid="search-input"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-1"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-2"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-3"
          disabled
        />
        <MultipleSelect
          placeholder="All Tags"
          options={[]}
          onChange={() => {}}
          data-testid="tags-select"
          disabled
        />
      </div>
      <div
        className="scrollable-weeks-container"
        data-testid="weeks-slider-container"
      >
        <WeeksSlider
          weeksList={weeksList}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          data-testid="weeks-slider"
        />
      </div>
      <div className="weeks-calendar" data-testid="weeks-calendar">
        {isRowSwitch ? (
          <>
            {selectedWeek && (
              <ProjectPlacementsList isLoading={false} rotasItems={rotasList} />
            )}
          </>
        ) : (
          <>
            {selectedWeek && (
              <RotasWeekCalendar
                project={project}
                selectedWeek={selectedWeek}
                data-testid="rotas-week-calendar"
                candidates={candidates}
                placements={placements}
                getPlacements={getPlacements}
                getProjectDetails={getProjectDetails}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
