import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFiles } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file-list.component';
import { DocumentsListComponent } from '../../document/documents-list.component';
import { useModal } from '../../../contexts/side-modal.context';

type ProfessionalRegistrationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'registrationCertificates' | 'registrationChecksFiles';

export const ProfessionalRegistration = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: ProfessionalRegistrationProps) => {
  const { id } = useParams();

  const { openModal, closeModal } = useModal();
  const onClickEdit = (fileKey: TFilesKey) => {
    openModal({
      title: 'Select File',
      component: (
        <SelectFileList
          fileKey={fileKey}
          onCancel={() => closeModal()}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list"
        />
      ),
    });
  };
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: HostedFile[] }[]
  >([
    { key: 'registrationCertificates', files: [] },
    { key: 'registrationChecksFiles', files: [] },
  ]);
  const { getValues, setValue } = useForm<{
    registrationCertificates: any;
    registrationChecksFiles: any;
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);

      prev[itemIndex].files = prev[itemIndex].files.filter(
        (file) => file._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFiles = Object.values(event.target.files as FileList);
    selectedFiles.map((file) => {
      // 5 MB file size limit
      if (file.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(
          `File < ${file.name} > size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`,
        );
        return;
      }
    });

    await uploadCandidateFiles(id!, selectedFiles)
      .then((response: HostedFile[]) => {
        event.target.value = '';
        response.forEach((file) => {
          addNewFile(key, file);
        });
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = [...prev[itemIndex].files, file];
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const isAlreadyUploaded = false;
  // const isAlreadyUploaded = useMemo(
  //   () =>
  //     !!filesList.find((el) => el.key === 'registrationCertificates')?.file
  //       ?.fileName,
  //   [filesList],
  // );

  const getFiles = (key: TFilesKey) => {
    const files =
      filesList
        .find((el) => el.key === key)
        ?.files?.filter((file) => file?.fileName) ?? [];

    return files;
  };

  const onUpdateCompliance = (
    key: 'registrationCertificates' | 'registrationChecksFiles',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance?._id) {
      const metadata = compliance?.metadata;
      const registrationCertificates = metadata?.registrationCertificates;
      const registrationChecksFiles = metadata?.registrationChecksFiles;
      setValue('registrationCertificates', registrationCertificates);
      setValue('registrationChecksFiles', registrationChecksFiles);
      setFilesList([
        {
          key: 'registrationCertificates',
          files: registrationCertificates ?? [],
        },
        {
          key: 'registrationChecksFiles',
          files: registrationChecksFiles ?? [],
        },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      {/* Prof Registration Certificate */}
      <TitleCard data-testid="title-card-prof-registration">
        Prof Registration Certificate
      </TitleCard>
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-registration"
      >
        <div className="col-md-6" data-testid="col-upload-registration">
          <FieldFile
            label="Upload file"
            onChange={(e) => {
              handleFileChange(e, 'registrationCertificates');
            }}
            disabled={isAlreadyUploaded}
            data-testid="field-file-registration"
            multiple={true}
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() =>
            !isAlreadyUploaded && onClickEdit('registrationCertificates')
          }
          data-testid="select-registration-btn"
        >
          <Button type="primary" data-testid="select-registration-btn-label">
            Select from files
          </Button>
        </div>
      </div>
      <DocumentsListComponent
        documents={getFiles('registrationCertificates')}
        onClickDelete={(id) => onClickDelete('registrationCertificates', id)}
        getDocuments={getDocuments}
        onDocumentRename={(_) => reFetchCompliance?.()}
        data-testid="documents-list-registration"
      />

      {/* Prof Registration Checks */}
      <TitleCard data-testid="title-card-prof-registration-checks">
        Prof Registration Checks
      </TitleCard>
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-registration-checks"
      >
        <div className="col-md-6" data-testid="col-upload-registration-checks">
          <FieldFile
            label="Upload file"
            onChange={(e) => handleFileChange(e, 'registrationChecksFiles')}
            data-testid="field-file-registration-checks"
            multiple={true}
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit('registrationChecksFiles')}
          data-testid="select-registration-checks-btn"
        >
          <Button
            type="primary"
            data-testid="select-registration-checks-btn-label"
          >
            Select from files
          </Button>
        </div>
      </div>
      <DocumentsListComponent
        documents={getFiles('registrationChecksFiles')}
        onClickDelete={(id) => {
          onClickDelete('registrationChecksFiles', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={(_) => {
          reFetchCompliance?.();
        }}
        data-testid="documents-list-registration-checks"
      />

      {/* Check Professional Registrations */}
      <TitleCard data-testid="title-card-check-prof-registration">
        Check Professional Registrations
      </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="checkRegistration"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        data-testid="check-registration-radio-group"
      />

      {/* Validate by AI Button */}
      <div className="info-card compliance-status buttons-container validated-by-ai">
        <Button
          type="primary"
          onClick={() => onClickSubmit(compliance._id, getValues(), true)}
          data-testid="validate-by-ai-btn"
        >
          Validate By AI
        </Button>
      </div>
    </>
  );
};
