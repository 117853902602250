import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  SearchInput,
  SubTitlePage,
  TitlePage,
  LeadForm,
  LeadContainerPage,
  LeadsList,
  ViewLead,
  CreateLeadForm,
  TabFilter,
} from '../../components';
import { ColumnSwitch, PlusIcon, RowSwitch } from '../../icons';
import { useNavigate } from 'react-router-dom';
import { Client, Lead, Trust, User } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterLeads,
  TFilterLead,
} from '../../utils';
import { toast } from 'react-toastify';
import { LeadsPipeline } from '../../components/lead/leads-pipeline';
import { leadStatusesList, leadTypesList } from '../../constants';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

type LeadsListPageProps = {
  isDashboard?: boolean;
  itemsPerPage?: number;
};

export const LeadsListPage = ({}: LeadsListPageProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const [isRowSwitch, setIsRowSwitch] = useState(false);

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: Lead) => {
    openConfirm({
      title: 'Archive Lead',
      component: (
        <>
          <span data-testid="confirm-modal-message">
            Do you want to archive this<b data-testid="record-name"> Lead</b> ?
          </span>
        </>
      ),
      onConfirm: () => deleteLead(item),
    });
  };

  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);

  const [leads, setLeads] = useState<Lead[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<TFilterLead>({
    search: '',
    statuses: [],
    types: [],
    tags: [],
    cpmIds: [],
  });

  const filteredData = useMemo(
    () => filterLeads(leads, filter),
    [leads, filter],
  );

  const openCreateFormModal = () => {
    openModal({
      title: '',
      component: (
        <CreateLeadForm
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          data-testid="lead-form"
          getLeads={getLeads}
        />
      ),
    });
  };

  const openFormModal = (lead?: Lead) => {
    openModal({
      title: '',
      component: (
        <LeadForm
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          selectedLead={lead}
          data-testid="lead-form"
          getLeads={getLeads}
        />
      ),
    });
  };

  const openViewModal = (lead: Lead) => {
    openModal({
      title: 'Lead details',
      component: <ViewLead lead={lead} data-testid="view-lead-component" />,
      actions: [
        {
          label: 'Edit Lead',
          action: () => openFormModal(lead),
          'data-testid': 'edit-lead-action',
        },
        {
          label: 'Delete Lead',
          action: () => onDeleteConfirm(lead!),
          'data-testid': 'delete-lead-action',
        },
      ],
    });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteLead = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerArchiveById(lead?._id!)
      .then(() => {
        toast.success('Lead archived successfully');
        closeModal();
        getLeads();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getLeads = async () => {
    setIsLoading(true);
    await AxiosInstance.leads
      .leadsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setLeads(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
    getClients();
    getTrusts();
    getLeads();
  }, []);

  return (
    <>
      <TabFilter
        data-testid="tab-filter"
        filters={[
          {
            title: 'Leads',
            url: ERoute.LEADS,
          },
          {
            title: 'Archives',
            url: ERoute.LEADS_ARCHIVE,
          },
        ]}
      />
      <LeadContainerPage data-testid="lead-container-page">
        {/* <CardContainer
          className="target-container"
          data-testid="target-container"
        >
          <div className="left-container" data-testid="kpi-left-container">
            <TitlePage data-testid="kpi-title">KPI Health</TitlePage>
            <SubTitlePage data-testid="kpi-subtitle">Q3</SubTitlePage>
          </div>
          <div className="right-container" data-testid="kpi-right-container">
            <div className="budget-container" data-testid="budget-container-1">
              <div className="title" data-testid="budget-title">
                Budget
              </div>
              <div className="numbers" data-testid="budget-numbers">
                <span className="green">£123,456</span> / £1,000,000
              </div>
              <ProgressBar value={100} data-testid="progress-bar-1" />
            </div>
            <div className="budget-container" data-testid="budget-container-2">
              <div className="title" data-testid="budget-title-2">
                Budget
              </div>
              <div className="numbers" data-testid="budget-numbers-2">
                <span className="green">£123,456</span> / £1,000,000
              </div>
              <ProgressBar value={66} data-testid="progress-bar-2" />
            </div>
            <div className="budget-container" data-testid="edit-icon-container">
              <div
                className="edit-icon"
                onClick={() => {}}
                data-testid="edit-icon"
              >
                <EditIcon />
              </div>
            </div>
          </div>
        </CardContainer> */}

        <CardContainer
          className="leads-list-container"
          data-testid="leads-list-container"
        >
          <HeaderPageContainer
            className="header-leads-list-container"
            data-testid="header-leads-list-container"
          >
            <div className="left-container" data-testid="leads-left-container">
              <TitlePage data-testid="leads-title">Leads</TitlePage>
              <SubTitlePage data-testid="leads-subtitle">
                Manage your leads
              </SubTitlePage>
            </div>
            <div
              className="right-container"
              data-testid="leads-right-container"
            >
              <Button
                type="primary"
                onClick={() => openCreateFormModal()}
                data-testid="create-new-lead-button"
              >
                <PlusIcon /> Create New Opportunity
              </Button>
              {isRowSwitch ? (
                <RowSwitch
                  onClick={() => setIsRowSwitch(false)}
                  data-testid="row-switch"
                />
              ) : (
                <ColumnSwitch
                  onClick={() => setIsRowSwitch(true)}
                  data-testid="column-switch"
                />
              )}
            </div>
          </HeaderPageContainer>
          <div className="filter-container">
            <SearchInput
              placeholder="Search lead"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
              }
            />
            <MultipleSelect
              placeholder="All Status"
              options={leadStatusesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  statuses: values ?? [],
                }));
              }}
            />
            {/* <MultipleSelect
              placeholder="All Tags"
              options={leadTagsList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  tags: values ?? [],
                }));
              }}
            /> */}
            <MultipleSelect
              placeholder="All CMPs"
              options={usersList.map((el) => ({
                label: el.firstName + ' ' + el.lastName,
                value: el._id,
              }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  cpmIds: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All Types"
              options={leadTypesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  types: values ?? [],
                }));
              }}
            />
          </div>
          {isRowSwitch ? (
            <LeadsList
              leads={filteredData}
              openViewModal={openViewModal}
              openEditFormModal={openFormModal}
              onDeleteConfirm={onDeleteConfirm}
              isLoading={isLoading}
            />
          ) : (
            <LeadsPipeline
              leads={filteredData}
              getLeads={getLeads}
              openViewModal={openViewModal}
              onClickCreate={openCreateFormModal}
              onDeleteConfirm={onDeleteConfirm}
            />
          )}
        </CardContainer>
      </LeadContainerPage>
    </>
  );
};
