import React, { useEffect, useState } from 'react';
import {
  RoleActivityProjectDto,
  Project,
  Activity,
} from '../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useModal } from '../../contexts/side-modal.context';
import { Button, FieldText, FormContainer, Select } from '../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  singleRoleSchema,
} from '../../utils';

import { toast } from 'react-toastify';
import { jobLevelWithGrades } from '../../constants';

type AddRoleActivityFormProps = {
  selectedProject: Project;
  selectedActivity: Activity;
  getProjectDetails: () => void;
};

export const AddRoleActivityForm = ({
  selectedProject,
  selectedActivity,
  getProjectDetails,
}: AddRoleActivityFormProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<RoleActivityProjectDto>({
    resolver: yupResolver(singleRoleSchema as any),
  });

  const formValues = watch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitActivityProject = async () => {
    setIsSubmitting(true);
    const values = getValues();

    const availableGrades =
      jobLevelWithGrades
        .find((el) => el.level === values.level)
        ?.grades?.map((el) => ({ label: el, value: el })) ?? [];

    if (!availableGrades.some((el) => el.value === values.grade)) {
      setError('grade', { message: 'Grade is required' });
      setValue('grade', null as any);
      return;
    }

    const requestDto: RoleActivityProjectDto = {
      ...values,
      expanse: 0,
    };

    await AxiosInstance.projects
      .projectsControllerAddRole(
        selectedProject._id,
        selectedActivity._id,
        requestDto,
      )
      .then(() => {
        getProjectDetails();
        toast.success('Activities Project saved successfully');
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({});
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <div className="row" data-testid={`role-row`}>
        <div className="col-md-6" data-testid={`role-select`}>
          <Select
            placeholder="Select job title"
            label="Job title"
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            register={register(`level`)}
            error={errors.level}
            data-testid="job-title-select"
            required
            control={control}
          />
        </div>
        <div className="col-md-6" data-testid={`role-select`}>
          <Select
            placeholder="Select grade"
            label="Grade"
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.level)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            register={register(`grade`)}
            error={errors.grade}
            disabled={!formValues.level}
            data-testid="grade-select"
            control={control}
            required
          />
        </div>
        <div className="col-md-6" data-testid={`timings-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="Timings"
            required
            value={'08:00'}
            type="time"
            disabled
          />
        </div>
        <div className="col-md-6" data-testid={`empty-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="&nbsp;"
            value={'16:00'}
            type="time"
            disabled
          />
        </div>
        <div className="col-md-6" data-testid={`role-rate-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="Rate per placement"
            required
            register={register(`rate`)}
            error={errors.rate}
            type="currency"
          />
        </div>
        <div className="col-md-6" data-testid={`role-expenses`}>
          <FieldText
            placeholder="Enter here ..."
            label="Expenses budget per placement"
            required
            register={register(`expanse`)}
            error={errors.expanse}
            type="currency"
          />
        </div>
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmitActivityProject)}
          disabled={isSubmitting}
          data-testid="button-create"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
