import React, { useEffect, useState } from 'react';
import { FieldText, Button, CardContainer, TitlePage, RadioGroup } from '../ui';
import {
  EFrequency,
  EFrequencyTimesheet,
  PayrollExportSettings,
} from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { daysOfWeekOptions } from '../../constants';

export const PayrollSetting = () => {
  const [payrollSettings, setPayrollSettings] =
    useState<PayrollExportSettings>();

  const getPayrollSettings = async () => {
    await AxiosInstance.payrollExports
      .payrollExportsControllerGetPayrollExportSettings()
      .then((response) => {
        if (!response) {
          return;
        }

        const result = response.data;
        setPayrollSettings({
          ...result,
          time: result.time,
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    if (!payrollSettings?.frequency || !payrollSettings?.time) {
      toast.error('Please fill the data');
      return;
    }
    setIsSubmitting(true);

    await AxiosInstance.payrollExports
      .payrollExportsControllerUpdatePayrollExportSettings({
        ...payrollSettings,
        time: payrollSettings.time,
      })
      .then(() => {
        toast.success('Payroll Settings updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsSubmitting(false);
  };

  useEffect(() => {
    getPayrollSettings();
  }, []);

  return (
    <CardContainer className="right-container" data-testid="card-container">
      <div
        className="settings-title-content"
        data-testid="settings-title-content"
      >
        <TitlePage>Payroll</TitlePage>
      </div>
      <hr />
      <div className="settings-content" data-testid="settings-content">
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <TitlePage data-testid="payroll-frequency-title">
            Select your payroll frequency
          </TitlePage>
          <br />
          <RadioGroup
            data-testid="payroll-frequency-radio-group"
            options={[
              {
                label: 'Every Day',
                value: EFrequency.Daily,
                description:
                  'Payroll for each payroll provider will be generated for export every 24hrs. This will be generated based on approved timesheets logged in the system and associated with the payroll provider selected for each candidate.',
              },
              {
                value: EFrequency.Weekly,
                label: 'Weekly',
                description:
                  'Payroll for each payroll provider will be generated for export every 7 days. This will be generated based on approved timesheets logged in the system and associated with the payroll provider selected for each candidate.',
              },
            ]}
            name="frequencyPayroll"
            value={payrollSettings?.frequency}
            onChange={(e) =>
              setPayrollSettings((prev: any) => ({
                ...prev,
                frequency: e,
              }))
            }
          />
          <br />
          <div className="days-container" data-testid="days-container">
            {payrollSettings?.frequency === EFrequencyTimesheet.Weekly && (
              <RadioGroup
                data-testid="weekly-day-radio-group"
                options={daysOfWeekOptions}
                name="dayPayroll"
                onChange={(value) =>
                  setPayrollSettings((prev: any) => ({
                    ...prev,
                    dayOfTheWeek: Number(value),
                  }))
                }
                value={payrollSettings?.dayOfTheWeek}
              />
            )}
            <br />
            <FieldText
              data-testid="time-input"
              placeholder="Enter here ..."
              label="Time (GMT)"
              required
              type="time"
              onChange={(e) =>
                setPayrollSettings((prev: any) => ({
                  ...prev,
                  time: e.target.value,
                }))
              }
              value={payrollSettings?.time}
            />
          </div>
          <div className="setting-actions" data-testid="setting-actions">
            <Button
              type="primary"
              onClick={onClickSubmit}
              disabled={isSubmitting}
              data-testid="save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
