import React, { useEffect, useState } from 'react';
import {
  DraggableArea,
  LeadCardPipeline,
  PipelineContainer,
  PipelineStatus,
} from '../ui';
import { leadStatusPipeline } from '../../constants';
import { ELeadStatus, ELeadType, Lead } from '../../backend/careo-api';
import { PlusIcon } from '../../icons';
import { useModal } from '../../contexts/side-modal.context';
import { AxiosInstance, formatCurrency } from '../../utils';
import { calculateMarginWithPercentage } from './lead-form/lead-financial-form-step';
import { UpdateLeadFinanCialForm } from './update-lead-financial-form';

const calculateRevenueTotal = (items: Lead[]) => {
  return items.reduce((total, el) => total + (el.revenue ?? 0), 0);
};

const calculateCostTotal = (items: Lead[]) => {
  return items.reduce((total, el) => total + (el.cost ?? 0), 0);
};

type LeadsPipelineProps = {
  leads: Lead[];
  getLeads: () => void;
  openViewModal: (data: Lead) => void;
  onClickCreate: () => void;
  onDeleteConfirm: (data: Lead) => void;
};

export const LeadsPipeline = ({
  leads,
  getLeads,
  openViewModal,
  onClickCreate,
  onDeleteConfirm,
}: LeadsPipelineProps) => {
  const [pipelines, setPipelines] = useState<PipelineStatus<Lead>[]>(
    leadStatusPipeline.map((el) => ({ ...el, items: [] })),
  );

  const { openModal, closeModal } = useModal();

  const openFinancialFormModal = (lead: Lead) => {
    openModal({
      title: '',
      component: (
        <UpdateLeadFinanCialForm
          step={-1}
          setStep={() => {}}
          createdLead={lead}
          setLead={() => {}}
          data-testid="financial-form-update"
          onSuccess={async () => {
            await AxiosInstance.leads.leadsControllerUpdateById(lead._id, {
              status: ELeadStatus.ProposalSent,
            });
            await getLeads();
          }}
        />
      ),
    });
  };

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = leadStatusPipeline[newListIndex].value;
    if (newStatus === ELeadStatus.ProposalSent)
      openFinancialFormModal(leads.find((i) => i._id === id)!);
    else
      await AxiosInstance.leads.leadsControllerUpdateById(id, {
        status: newStatus,
      });
  };

  useEffect(() => {
    const groupedLeads = leads.reduce<Record<string, Lead[]>>((acc, curr) => {
      const status = curr.status;
      acc[status] = acc[status] ? [...acc[status], curr] : [curr];
      return acc;
    }, {});

    setPipelines((prevPipelines) =>
      prevPipelines.map((pipeline) => ({
        ...pipeline,
        items: groupedLeads[pipeline.value] || [],
      })),
    );
  }, [leads]);

  const calculateMarginTotal = (items: Lead[]) => {
    let total = 0;
    items.forEach((el) => {
      if (el.revenue && el.cost) {
        total += calculateMarginWithPercentage(el.revenue, el.cost)?.value ?? 0;
      }
    });
    return total;
  };

  return (
    <PipelineContainer>
      {pipelines.map((el, listIndex: number) => {
        const newLeadsCount = el.items.filter(
          (item) => item.type === ELeadType.New,
        ).length;
        const extensionLeadsCount = el.items.filter(
          (item) => item.type === ELeadType.Extension,
        ).length;
        const tenderLeadsCount = el.items.filter(
          (item) => item.type === ELeadType.Tender,
        ).length;

        const totalRevenue = calculateRevenueTotal(el.items);
        const totalCost = calculateCostTotal(el.items);
        const totalMargin = calculateMarginTotal(el.items);

        return (
          <div>
            <div className="totalizer">
              <div className="totalizer-item revenue-totalizer">
                <div className="label">Revenue: </div>
                <div className="value">{formatCurrency(totalRevenue)}</div>
              </div>
              <div className="totalizer-item cost-totalizer">
                <div className="label">Cost: </div>
                <div className="value">{formatCurrency(totalCost)}</div>
              </div>
              <div className="totalizer-item margin-totalizer">
                <div className="label">Margin: </div>
                <div className="value">{formatCurrency(totalMargin)}</div>
              </div>
            </div>
            <div className="item-container" key={listIndex}>
              <div className="item-header">
                <div className="item-title">
                  <label>{el.label}</label>
                  <div className="item-total type-new" title="new">
                    {newLeadsCount}
                  </div>
                  <div className="item-total type-tender" title="extension">
                    {extensionLeadsCount}
                  </div>
                  <div className="item-total type-extension" title="tender">
                    {tenderLeadsCount}
                  </div>
                </div>
                {el.value === ELeadStatus.NewOpportunity && (
                  <div className="item-action" onClick={onClickCreate}>
                    <PlusIcon />
                  </div>
                )}
              </div>
              <div className="cards-list-container">
                {el.isDraggableTo ? (
                  <DraggableArea
                    pipeline={pipelines}
                    listIndex={listIndex}
                    itemIndex={0}
                    onSuccess={getLeads}
                    onDragRequest={handleDrop}
                  />
                ) : (
                  <div className="draggable-area false">
                    <hr />
                  </div>
                )}
                {el.items.map((item, itemIndex: number) => (
                  <>
                    <LeadCardPipeline
                      item={item}
                      listIndex={listIndex}
                      itemIndex={itemIndex}
                      openViewModal={openViewModal}
                      getLeads={getLeads}
                      onDeleteConfirm={onDeleteConfirm}
                    />
                    {el.isDraggableTo ? (
                      <DraggableArea
                        pipeline={pipelines}
                        listIndex={listIndex}
                        itemIndex={0}
                        onSuccess={getLeads}
                        onDragRequest={handleDrop}
                      />
                    ) : (
                      <div className="draggable-area false">
                        <hr />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </PipelineContainer>
  );
};
