import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const ProjectDetailsContainer = styled.div`
  .project-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .back-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }

  .task-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .action-item {
    .disabled {
      cursor: not-allowed;
      background-color: gray;
    }
  }

  .notes-header-container {
    display: flex;
    justify-content: space-between;
  }

  .matching-list-content {
    .filter-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .title-page {
        flex: 1;
      }
      .search-input {
        flex: unset;
      }

      margin-bottom: 24px;
    }
  }

  .rotas-list-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .rotas-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rotas-header-left-container {
        display: flex;
        align-items: center;
        .selected-value {
          font-size: 24px;
          color: #000;
        }
      }
      .rotas-header-right-container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .rotas-filter-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      .search-input {
        max-width: 300px;
      }
      > div {
        width: 200px;
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .project-header {
      .back-button {
        width: 20px;
        height: 20px;
      }
    }

    .matching-list-content {
      .filter-container {
        margin-bottom: 12px;
      }
    }

    .rotas-list-content {
      gap: 12px;
      .rotas-header-container {
        align-items: flex-start;
        flex-direction: column;
        .rotas-header-left-container {
          .selected-value {
            font-size: 14px;
          }
        }
        .rotas-header-right-container {
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100%;
          justify-content: flex-end;
        }
      }
      .rotas-filter-container {
        gap: 10px;
        .search-input {
          max-width: 100%;
        }
        > div {
          width: 100%;
        }
      }
    }
  }
`;
