import React from 'react';
import styled from 'styled-components';
import { Badge } from '../../ui';
import {
  Activity,
  Application,
  EApplicationApprovalStatus,
  Job,
  Project,
} from '../../../backend/careo-api';
import { DotsIcon } from '../../../icons';
import { Menu, MenuItem } from '@mui/material';
import { applicationApprovalStatusList, TUIType } from '../../../constants';
import { useModal } from '../../../contexts/side-modal.context';
import { FillPlacement } from './fill-placement-form';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { useConfirm } from '../../../contexts/confirm-modal.context';
import { rejectedPlacementStatuses } from '../../../pages/placement/placements-list/placements-list.page';

export const RotasCardContainer = styled.div<{
  type: TUIType;
}>`
  border-radius: 4px;
  ${({ type }) => {
    const styles: any = {
      neutral: {
        border: '1px solid #337e89',
        background: 'rgba(51, 126, 137, 0.05)',
      },
      danger: {
        border: '1px solid #E03137',
        background: 'rgba(224, 49, 55, 0.05)',
      },
      success: {
        border: '1px solid #0CAF60',
        background: 'rgba(12, 175, 96, 0.05)',
      },
      warning: {
        border: '1px solid #FFAE00',
        background: 'rgba(255, 174, 0, 0.05)',
      },
    };

    const { border, background } = styles[type] || styles.neutral;

    return `
      border: ${border};
      background: ${background};
    `;
  }}

  padding: 8px;
  font-size: 12px;
  font-weight: 400;

  .top-container {
    display: flex;
    justify-content: space-between;
    .left-container {
      .name {
        font-weight: 400;
      }
      .time {
        color: #747474;
      }
    }
    .right-container {
      display: flex;
      gap: 5px;
      cursor: pointer;

      .action-button {
        ${({ type }) => type === 'neutral' && 'cursor: pointer; '}
        &:hover {
          ${({ type }) => type === 'neutral' && 'opacity: 0.5; '}
        }
        svg {
          path {
            fill: #7a7c7e;
          }
        }
      }
    }
  }
  .compliance-container {
    color: #747474;
    font-size: 10px;
  }
`;

type RotasCardProps = {
  application: Application;
  getPlacements: () => void;
  date: Date;
  activity: Activity;
  role: Job;
  project: Project;
};

export const RotasCard = ({
  application,
  getPlacements,
  activity,
  role,
  project,
  date,
}: RotasCardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onClickCard();
    handleClose();
  };

  const handleCancel = (item: Application) => {
    onDeleteConfirm(item);
    handleClose();
  };

  const onDeleteConfirm = (item: Application) => {
    openConfirm({
      title: 'Cancel Placement',
      component: (
        <>
          Do you want to cancel this placement ? <br />
          {new Date(item?.availableFrom ?? '') <= new Date() ? (
            <>
              Note: <b>Placement is already started</b>
            </>
          ) : item?.approvalStatus !== EApplicationApprovalStatus.NOT_ACTIVE ? (
            <>
              Note: <b>Placement is already validated</b>
            </>
          ) : (
            <></>
          )}
        </>
      ),
      onConfirm: () => deletePlacement(item),
    });
  };

  const deletePlacement = async (placement: Application) => {
    await AxiosInstance.applications
      .applicationsControllerDeleteApplication(placement?._id!)
      .then(() => {
        toast.success('Placement Removed successfully');
        getPlacements();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    closeConfirm();
  };

  const onClickCard = () => {
    openModal({
      title: application.candidate ? 'Edit Placement' : 'Fill Placement',
      component: (
        <FillPlacement
          onCancel={() => {
            closeModal();
          }}
          onSuccess={() => {
            getPlacements();
            closeModal();
          }}
          date={date}
          activity={activity}
          role={role}
          project={project}
          placement={application}
        />
      ),
    });
  };

  const applicationStatus =
    applicationApprovalStatusList.find(
      (el) => el.value === application.approvalStatus,
    ) ?? applicationApprovalStatusList[0];

  return (
    <>
      <RotasCardContainer type={applicationStatus.type}>
        <div className="top-container">
          <div className="left-container" data-testid="left-container">
            <div className="name" data-testid="rota-name">
              {application.candidate ? (
                <>
                  {application.candidate?.firstName}{' '}
                  {application.candidate?.lastName}
                </>
              ) : (
                <>-</>
              )}
            </div>
            <div className="time" data-testid="rota-time">
              8:00 - 16:00
            </div>
          </div>
          <div className="right-container" data-testid="right-container">
            <Badge type={applicationStatus?.type} data-testid="badge-complete">
              {applicationStatus?.label}
            </Badge>
            <div className="action-button">
              {!rejectedPlacementStatuses.includes(
                application.approvalStatus!,
              ) && (
                <DotsIcon data-testid="dots-icon" onClick={handleClickMenu} />
              )}
            </div>
          </div>
        </div>
        {/* <div className="compliance-container">
        <div className="title-compliance">Compliance</div>
        <ProgressBar value={50} />
      </div> */}
      </RotasCardContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testid="dropdown-menu"
      >
        <MenuItem
          onClick={() => handleEdit()}
          data-testid={`dropdown-menu-item-edit`}
        >
          {application.candidate ? 'Edit' : 'Fill'}
        </MenuItem>
        <MenuItem
          onClick={() => handleCancel(application)}
          data-testid={`dropdown-menu-item-cancel`}
        >
          {application.candidate ? 'Cancel' : 'Remove'}
        </MenuItem>
      </Menu>
    </>
  );
};
