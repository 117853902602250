import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AssignCandidateSchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../../utils';
import {
  Activity,
  Application,
  AssignCandidateToVacantPlacementDto,
  Candidate,
  EApplicationStatus,
  Job,
  Project,
} from '../../../backend/careo-api';
import { Button, FieldText, FormContainer, Select, TitleCard } from '../../ui';
import {
  jobLevelWithGrades,
  projectActivityTypeList,
} from '../../../constants';
import { toast } from 'react-toastify';

export const FillPlacement = ({
  onCancel,
  onSuccess,
  date,
  activity,
  role,
  project,
  placement,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  date: Date;
  activity: Activity;
  role: Job;
  project: Project;
  placement: Application;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<AssignCandidateToVacantPlacementDto>({
    resolver: yupResolver(AssignCandidateSchema),
  });

  const [candidates, setCandidates] = useState<Candidate[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.projects
      .projectsControllerAssignCandidateToVacant(project._id, {
        candidateId: values.candidateId,
        placementId: values.placementId,
      })
      .then(() => {
        toast.success('Placement added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  const getShortlistedCandidates = () => {
    AxiosInstance.applications
      .applicationsControllerFindAll({ jobId: role._id })
      .then((response) => {
        console.log(response.data.items);
        setCandidates(
          response.data.items
            .filter(
              (el) =>
                el.candidate && el.status === EApplicationStatus.Shortlist,
            )
            .map((el) => el.candidate as Candidate),
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    getShortlistedCandidates();
    reset({
      placementId: placement._id,
      candidateId: placement.candidate?._id ?? undefined,
    });
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <div className="row" data-testid="row-dates">
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Day"
            required
            type="date"
            value={date.toISOString()?.split('T')?.[0] ?? ''}
            data-testid="field-start-date"
            disabled
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <Select
            placeholder="Select activity type"
            label="Activity Type"
            required
            options={projectActivityTypeList.map((el) => ({
              label: el,
              value: el,
            }))}
            value={activity.activityType}
            data-testid="select-recruiter"
            disabled
          />
        </div>
        <div className="col-md-6" data-testid="col-start-date">
          <Select
            placeholder="Select job title"
            label="Job title"
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            value={role.level}
            disabled
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <Select
            placeholder="Select grade"
            label="Grade"
            options={
              jobLevelWithGrades
                .find((el) => el.level === role.level)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            value={role.grade}
            disabled
          />
        </div>
        <div className="col-md-12" data-testid="col-end-date">
          <Select
            placeholder="Select candidate"
            label="Candidate"
            options={candidates.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            register={register('candidateId')}
            error={errors.candidateId}
            control={control}
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="Rate"
            required
            value={role.rate}
            data-testid="field-job-description"
            disabled
            type="currency"
          />
        </div>
      </div>
      <TitleCard data-testid="title-vacancy-details">Expenses</TitleCard>
      <div className="row" data-testid="row-dates">
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Accommodation"
            value={0}
            data-testid="field-job-description"
            disabled
            type="currency"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="Travel"
            value={0}
            data-testid="field-job-description"
            disabled
            type="currency"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="Food"
            value={0}
            data-testid="field-job-description"
            disabled
            type="currency"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="Other"
            value={0}
            data-testid="field-job-description"
            disabled
            type="currency"
          />
        </div>
      </div>
      <TitleCard data-testid="title-vacancy-details">Payroll</TitleCard>
      <div className="row" data-testid="row-dates">
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Total Payroll Cost"
            required
            value={role.rate}
            disabled
            type="currency"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="Total Worker Cost"
            required
            value={role.rate}
            disabled
            type="currency"
          />
        </div>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-create"
        >
          Save
        </Button>
      </div>
    </FormContainer>
  );
};
