import { DonutChart, DonutChartConfig } from '../charts/donut-chart';
import { RevenueChartContainer } from '../project/charts/revenue-chart';

const donutChartConfig: DonutChartConfig = {
  labels: ['Complete', 'In Progress', 'Expired'],
  backgroundColors: ['#27A376', '#ffea00', '#ff0000'],
  borderColors: ['#27A376', '#ffea00', '#ff0000'],
  size: 250,
  subtitle: 'Total Cand',
  title: 'Compliance',
};

type Props = {
  data: number[];
  isLoading?: boolean;
};

export const DashboardComplianceChart = ({ data, isLoading }: Props) => {
  return (
    <RevenueChartContainer data-testid="revenue-chart-container">
      <div className="header" data-testid="header">
        <span className="title" data-testid="header-title">
          Compliance
        </span>
      </div>
      <DonutChart
        config={donutChartConfig}
        data={data}
        isLoading={isLoading}
        data-testid="donut-chart"
      />

      <div className="labels-container" data-testid="labels-container">
        {donutChartConfig.labels.map((label, index) => (
          <div className="label" key={index} data-testid={`label-${index}`}>
            <div>
              <div
                style={{
                  backgroundColor: donutChartConfig.backgroundColors?.[index],
                }}
                data-testid={`label-color-${index}`}
              ></div>
              <div data-testid={`label-text-${index}`}>{label}</div>
            </div>
            <div data-testid={`label-data-${index}`}>{data[index]}</div>
          </div>
        ))}
      </div>
    </RevenueChartContainer>
  );
};
