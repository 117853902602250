import { Client, Trust, User } from '../../backend/careo-api';
import { FormContainer, TitlePage } from '../ui';
import { GeneralFormStep } from './lead-form/general-form-step';

export type CreateLeadFormProps = {
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  getLeads: () => void;
};

export const CreateLeadForm = ({
  usersList,
  trustsList,
  clientsList,
  getLeads,
}: CreateLeadFormProps) => {
  return (
    <FormContainer data-testid="form-container">
      <div className="side-modal-header-container">
        <TitlePage className="side-modal-title">Lead info</TitlePage>
      </div>
      <GeneralFormStep
        setStep={() => {}}
        data-testid="create-lead-form"
        getLeads={getLeads}
        usersList={usersList}
        trustsList={trustsList}
        clientsList={clientsList}
        createdLead={undefined}
        setCreatedLead={() => {}}
        isStepper={false}
      />
    </FormContainer>
  );
};
