import * as yup from 'yup';

export const PlacementCreateByApplicationIdSchema = yup.object().shape({
  applicationId: yup.string().required('Application is required'),
});

export const PlacementValidateSchema = yup.object().shape({
  fee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Fee must be a valid number.')
    .required('Fee is required.')
    .positive('Fee must be a positive number'),

  vat: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('VAT must be a valid number.')
    .required('VAT is required.')
    .min(0, 'VAT must be 0 or a positive number.'),

  onCallFee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Charge must be a valid number.')
    .required('On Call Charge is required.')
    .positive('On Call Charge must be a positive number')
    .test(
      'is-greater-than',
      'On Call Charge must be greater than On Call Rate',
      function (value) {
        const onCallRate = this.parent.onCallRate;
        return value > onCallRate;
      },
    ),

  onCallRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Rate must be a valid number.')
    .required('On Call Rate is required.')
    .positive('On Call Rate must be a positive number'),
});

export const AssignCandidateSchema = yup.object().shape({
  candidateId: yup.string().required('Candidate is required'),
  placementId: yup.string().required('Placement is required'),
  accommodation: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Accommodation must be a valid number.')
    .default(0)
    .min(0, 'Accommodation must be 0 or a positive number'),
  travel: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Travel must be a valid number.')
    .default(0)
    .min(0, 'Travel must be 0 or a positive number'),
  food: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Food must be a valid number.')
    .default(0)
    .min(0, 'Food must be 0 or a positive number'),
  other: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Other Expenses must be a valid number.')
    .default(0)
    .min(0, 'Other Expenses  must be 0 or a positive number'),

  confirmationSent: yup.string().optional(),
  invoiceReceived: yup.string().optional(),
  invoicePaid: yup.string().optional(),

  notes: yup.string(),

  serviceLog: yup.string(),
});
