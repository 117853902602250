import React, { useEffect, useState } from 'react';
import {
  Activity,
  Application,
  Candidate,
  Job,
  Project,
} from '../../backend/careo-api';

import { PlusIcon } from '../../icons';
import { Button, SearchInput, Select, TitleCard } from '../ui';
import { ProjectMatchingPipeline } from './project-matching-pipeline.component';
import { AxiosInstance } from '../../utils';
import { toast } from 'react-toastify';
import { useModal } from '../../contexts/side-modal.context';
import { AddCandidateRole } from '../vacancy';

type ProjectDetailsMatchingProps = {
  project: Project;
  candidatesList: Candidate[];
};

export const ProjectDetailsMatching = ({
  project,
  candidatesList,
}: ProjectDetailsMatchingProps) => {
  // const [isRowSwitch, setIsRowSwitch] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [selectedRole, setSelectedRole] = useState<Job>();
  const { openModal, closeModal } = useModal();

  const getApplications = (jobId: string) => {
    AxiosInstance.applications
      .applicationsControllerFindAll({ jobId })
      .then((response) => {
        setApplications(response.data.items);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickCreate = (job: Job) => {
    openModal({
      title: 'Add Candidate to Role',
      component: (
        <AddCandidateRole
          candidates={candidatesList}
          job={job}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getApplications(job._id);
            closeModal();
          }}
          data-testid="add-candidate-role"
        />
      ),
    });
  };

  useEffect(() => {
    if (selectedRole) {
      getApplications(selectedRole._id);
    }
  }, [selectedRole]);

  useEffect(() => {
    setSelectedActivity(project.activities?.[0] ?? undefined);
    setSelectedRole(project.activities?.[0]?.roles?.[0] ?? undefined);
  }, []);

  return (
    <div className="matching-list-content" data-testid="matching-list-content">
      <div className="filter-container" data-testid="filter-container">
        <TitleCard className="title-page" data-testid="role-matching-title">
          Role Matching
        </TitleCard>
        <SearchInput
          placeholder="Search candidates"
          onChange={(e) => {}}
          data-testid="search-input"
          disabled
        />{' '}
        <Select
          placeholder="All Activities"
          options={project.activities.map((el) => ({
            label: el.subSpecialty,
            value: el._id,
          }))}
          onChange={(value) => {
            setSelectedActivity(
              project.activities.find((el) => el._id === value),
            );
            setSelectedRole(undefined);
          }}
          value={selectedActivity?._id}
          data-testid="status-select"
        />
        <Select
          placeholder="All Roles"
          options={
            selectedActivity?.roles.map((el) => ({
              label: `${el.level} ${el.grade}`,
              value: el._id,
            })) ?? []
          }
          onChange={(value) => {
            setSelectedRole(
              selectedActivity?.roles.find((el) => el._id === value),
            );
          }}
          data-testid="tags-select"
          disabled={!selectedActivity?._id}
          value={selectedRole?._id}
        />
        {/* {isRowSwitch ? (
          <RowSwitch
            onClick={() => setIsRowSwitch(false)}
            data-testid="row-switch"
          />
        ) : (
          <ColumnSwitch
            onClick={() => setIsRowSwitch(true)}
            data-testid="column-switch"
          />
        )} */}
        <Button
          type="primary"
          onClick={() => selectedRole?._id && onClickCreate(selectedRole)}
          data-testid="add-candidates-button"
          disabled={!selectedRole?._id}
        >
          <PlusIcon data-testid="plus-icon" /> Add Candidates
        </Button>
      </div>
      {/* {isRowSwitch ? (
        <ProjectMatchingList
          data-testid="project-matching-list"
          applications={applications}
        />
      ) : ( */}
      {selectedRole?._id ? (
        <ProjectMatchingPipeline
          data-testid="project-matching-pipeline"
          applicationsList={applications}
          getApplications={() => getApplications(selectedRole?._id)}
        />
      ) : (
        <>Please select an activity and role</>
      )}

      {/* )} */}
    </div>
  );
};
