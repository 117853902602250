import React, { useState } from 'react';
import { Select, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Candidate, CreateApplicationDto, Job } from '../../backend/careo-api';
import {
  AddCandidateRoleSchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../utils';
import { toast } from 'react-toastify';
import { applicationProjectStatusList } from '../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../utils';

type AddCandidateRoleProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidates: Candidate[];
  job: Job;
};

export const AddCandidateRole = ({
  onCancel,
  onSuccess,
  candidates,
  job,
}: AddCandidateRoleProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(AddCandidateRoleSchema as any),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.applications
      .applicationsControllerCreate({
        ...values,
        jobId: job._id,
      })
      .then(() => {
        toast.success('Candidate added to vacancy successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <Select
        options={candidates.map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          value: el._id,
        }))}
        placeholder="Option"
        label="Candidate"
        required
        register={register('candidateId', { required: true })}
        error={errors.candidateId}
        data-testid="select-candidate"
      />
      <Select
        options={applicationProjectStatusList.filter((el) => el.isDraggableTo)}
        placeholder="Option"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
        data-testid="select-status"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-create"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
