import React, { useEffect, useMemo, useState } from 'react';
import {
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  SearchInput,
  SubTitlePage,
  TitlePage,
  LeadForm,
  LeadContainerPage,
  LeadsList,
  ViewLead,
  TabFilter,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import { Client, Lead, Trust, User } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterLeads,
  TFilterLead,
} from '../../utils';
import { toast } from 'react-toastify';
import { leadStatusesList, leadTypesList } from '../../constants';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

type LeadsListPageProps = {
  isDashboard?: boolean;
  itemsPerPage?: number;
};

export const LeadsArchiveListPage = ({}: LeadsListPageProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: Lead) => {
    openConfirm({
      title: 'Delete Lead',
      component: (
        <>
          <span data-testid="confirm-modal-message">
            Do you want to delete this<b data-testid="record-name"> Lead</b> ?
          </span>
        </>
      ),
      onConfirm: () => deleteLead(item),
    });
  };

  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);

  const [leads, setLeads] = useState<Lead[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<TFilterLead>({
    search: '',
    statuses: [],
    types: [],
    tags: [],
    cpmIds: [],
  });

  const filteredData = useMemo(
    () => filterLeads(leads, filter),
    [leads, filter],
  );

  const openFormModal = (lead?: Lead) => {
    openModal({
      title: '',
      component: (
        <LeadForm
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          selectedLead={lead}
          data-testid="lead-form"
          getLeads={getLeads}
        />
      ),
    });
  };

  const openViewModal = (lead: Lead) => {
    openModal({
      title: 'Lead details',
      component: <ViewLead lead={lead} data-testid="view-lead-component" />,
    });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteLead = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerDeleteById(lead?._id!)
      .then(() => {
        toast.success('Lead Removed successfully');
        closeModal();
        getLeads();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const revertArchiveLead = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerRevertArchiveById(lead?._id!)
      .then(() => {
        toast.success('Lead reverted successfully');
        closeModal();
        getLeads();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getLeads = async () => {
    setIsLoading(true);
    await AxiosInstance.leads
      .leadsControllerFindAll({ isArchive: true })
      .then((response) => {
        const result = response.data.items;
        setLeads(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const onRevertConfirm = (item: Lead) => {
    openConfirm({
      title: 'Revert Archive Lead',
      component: (
        <>
          <span data-testid="confirm-modal-message">
            Do you want to revert this<b data-testid="record-name"> Lead</b> ?
          </span>
        </>
      ),
      onConfirm: () => deleteLead(item),
    });
  };

  useEffect(() => {
    getUsers();
    getClients();
    getTrusts();
    getLeads();
  }, []);

  return (
    <>
      <TabFilter
        data-testid="tab-filter"
        filters={[
          {
            title: 'Leads',
            url: ERoute.LEADS,
          },
          {
            title: 'Archives',
            url: ERoute.LEADS_ARCHIVE,
          },
        ]}
      />
      <LeadContainerPage data-testid="lead-container-page">
        <CardContainer
          className="leads-list-container"
          data-testid="leads-list-container"
        >
          <HeaderPageContainer
            className="header-leads-list-container"
            data-testid="header-leads-list-container"
          >
            <div className="left-container" data-testid="leads-left-container">
              <TitlePage data-testid="leads-title">Archive Leads</TitlePage>
              <SubTitlePage data-testid="leads-subtitle">
                Manage your leads
              </SubTitlePage>
            </div>
            <div
              className="right-container"
              data-testid="leads-right-container"
            ></div>
          </HeaderPageContainer>
          <div className="filter-container">
            <SearchInput
              placeholder="Search lead"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
              }
            />
            <MultipleSelect
              placeholder="All Status"
              options={leadStatusesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  statuses: values ?? [],
                }));
              }}
            />
            {/* <MultipleSelect
              placeholder="All Tags"
              options={leadTagsList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  tags: values ?? [],
                }));
              }}
            /> */}
            <MultipleSelect
              placeholder="All CMPs"
              options={usersList.map((el) => ({
                label: el.firstName + ' ' + el.lastName,
                value: el._id,
              }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  cpmIds: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All Types"
              options={leadTypesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  types: values ?? [],
                }));
              }}
            />
          </div>
          <LeadsList
            leads={filteredData}
            openViewModal={openViewModal}
            openEditFormModal={openFormModal}
            onDeleteConfirm={onDeleteConfirm}
            isLoading={isLoading}
            onRevertConfirm={onRevertConfirm}
          />
        </CardContainer>
      </LeadContainerPage>
    </>
  );
};
