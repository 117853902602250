import React from 'react';
import { AddressDetails } from '../address/address-details.component';
import { Project } from '../../backend/careo-api';
import { Abbreviation, Badge, InfoCardRow } from '../ui';

type ProjectDetailsGeneralProps = {
  project: Project;
};

export const ProjectDetailsGeneral = ({
  project,
}: ProjectDetailsGeneralProps) => {
  return (
    <>
      <div className="info-card" data-testid="personal-info-card">
        <div className="info-card-title" data-testid="personal-info-title">
          Personal Info
        </div>
        <hr />
        <div className="row" data-testid="personal-info-row">
          <div className="col-md-6" data-testid="personal-info-col-left">
            <div
              className="info-card-content row"
              data-testid="personal-info-left"
            >
              <InfoCardRow
                title="Hospital"
                data={project.client.clientName}
                dataTestId="hospital"
              />
              <InfoCardRow
                title="Region"
                data={project.region}
                dataTestId="region"
              />
              <InfoCardRow
                title="Category"
                data={project.specialty}
                dataTestId="specialty"
              />

              <InfoCardRow
                title="Reporting Instructions"
                data={project.report}
                dataTestId="reporting-instructions"
              />
            </div>
          </div>
          <div className="col-md-6" data-testid="personal-info-col-right">
            <div
              className="info-card-content row"
              data-testid="personal-info-right"
            >
              <InfoCardRow
                title="CPM"
                data={
                  <div className="d-flex gap-2 flex-wrap">
                    {project.cpms.map((el) => (
                      <Badge
                        type="neutral"
                        key={`${el?.firstName}-${el?.lastName}`}
                      >
                        <Abbreviation>
                          {el?.firstName[0]}
                          {el?.lastName[0]}
                        </Abbreviation>
                        {el?.firstName} {el?.lastName}
                      </Badge>
                    ))}
                  </div>
                }
                dataTestId="cpm"
              />
              <InfoCardRow
                title="Service Coordinator"
                data={
                  project.serviceCoordinator ? (
                    <Badge type="neutral">
                      <Abbreviation>
                        {project.serviceCoordinator?.firstName[0]}
                        {project.serviceCoordinator?.lastName[0]}
                      </Abbreviation>
                      {project.serviceCoordinator?.firstName}{' '}
                      {project.serviceCoordinator?.lastName}
                    </Badge>
                  ) : undefined
                }
                dataTestId="service-coordinator"
              />
              <InfoCardRow
                title="CEM"
                data={
                  project.cem ? (
                    <Badge type="neutral">
                      <Abbreviation>
                        {project.cem?.firstName[0]}
                        {project.cem?.lastName[0]}
                      </Abbreviation>
                      {project.cem?.firstName} {project.cem?.lastName}
                    </Badge>
                  ) : undefined
                }
                dataTestId="cem"
              />
            </div>
          </div>
        </div>
      </div>
      <AddressDetails
        address={project.client?.address}
        data-testid="address-details"
      />
    </>
  );
};
