import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const SelectContainer = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    span {
      color: red;
    }
  }

  .error-container {
    font-size: 13px;
    color: red;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #e9eaec !important;
    border-color: ${(props) => (props.hasError ? 'red !important' : '')};
    outline: unset !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline,
  .mui-input fieldset.MuiOutlinedInput-notchedOutline:focus-visible {
    border: 1.5px solid #c2c3c6 !important;
    border-color: ${(props) => (props.hasError ? 'red !important' : '')};
  }

  &.with-tags
    .MuiAutocomplete-root
    .MuiFormControl-root
    .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    gap: 5px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled {
    background-color: #fafafa;
    input {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #000;
      -webkit-text-fill-color: unset;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: 100%;
    label {
      font-size: 10px;
    }
    .error-container {
      font-size: 9px;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      font-size: 10px;
    }
    .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    .Mui-disabled input,
    input {
      font-size: 10px !important;
    }

    span.MuiChip-label.MuiChip-labelMedium {
      font-size: 8px;
    }

    ul.MuiAutocomplete-listbox li.MuiAutocomplete-option {
      font-size: 10px;
      min-height: 30px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      height: 40px;
    }
  }
`;
