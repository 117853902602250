import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  SearchInput,
  SubTitlePage,
  Table,
  TitlePage,
  HeaderPageContainer,
  MultipleSelect,
  Pagination,
  ProjectForm,
  Badge,
  ProgressBar,
  Abbreviation,
} from '../../components';

import { EditIcon, EyeIcon, PlusIcon, SortIcon } from '../../icons';

import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterProjects,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortProjects,
  TFilterProject,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Client, Project, Trust, User } from '../../backend/careo-api';
import { useModal } from '../../contexts/side-modal.context';
import { toast } from 'react-toastify';
import { calculateProjectProgress } from '../../components/project/project-form';
import { regionsWithCounties, specialtiesList } from '../../constants';

type ProjectsListPageProps = {
  itemsPerPage?: number;
};

export const ProjectsListPage = ({
  itemsPerPage = 7,
}: ProjectsListPageProps) => {
  const { openModal } = useModal();

  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);

  const [filter, setFilter] = useState<TFilterProject>({
    search: '',
    cpmIds: [],
    clientsIds: [],
    specialties: [],
    regions: [],
  });

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const openFormModal = (project?: Project) => {
    openModal({
      title: '',
      component: (
        <ProjectForm
          data-testid="create-new-project-form"
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          selectedProject={project}
          getProjects={getProjects}
        />
      ),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PROJECTS}/${id}`);
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getProjects = async () => {
    setIsLoading(true);
    await AxiosInstance.projects
      .projectsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setProjects(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(projectsList, currentPage, itemsPerPage);
  }, [projectsList, currentPage]);

  useEffect(() => {
    const filteredData = filterProjects(projects, filter);
    const sortedData = sortProjects(filteredData, sort);
    setProjectsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(filteredData.length, itemsPerPage));
  }, [projects, filter, sort]);

  useEffect(() => {
    getProjects();
    getUsers();
    getClients();
    getTrusts();
  }, []);

  return (
    <>
      <CardContainer
        className="project-list-card-container"
        data-testid="project-list-card-container"
      >
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Projects</TitlePage>
            <SubTitlePage data-testid="subtitle-page">
              Manage your Project
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <Button
              type="primary"
              onClick={() => openFormModal()}
              data-testid="add-new-button"
            >
              <PlusIcon data-testid="plus-icon" /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search project"
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                search: e.target.value ?? '',
              }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All CPMs"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                cpmIds: values ?? [],
              }));
            }}
            options={usersList.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            data-testid="multiple-select-teams"
          />
          <MultipleSelect
            placeholder="All Clients"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                clientsIds: values ?? [],
              }));
            }}
            options={clientsList.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            data-testid="multiple-select-grades"
          />
          <MultipleSelect
            placeholder="All Specialties"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, specialties: values ?? [] }));
            }}
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            data-testid="multiple-select-recruiters"
          />
          <MultipleSelect
            placeholder="All Regions"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, regions: values ?? [] }));
            }}
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            data-testid="multiple-select-status"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="table">
            <thead>
              <tr>
                <th
                  className="checkbox-table"
                  data-testid="checkbox-table-header"
                ></th>
                <th
                  onClick={() => onSelectSort('name', setSort)}
                  data-testid="sort-project-name"
                >
                  <div>
                    <label>name </label>
                    <SortIcon
                      value={sort.key === 'name' ? sort.value : ''}
                      data-testid="sort-project-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('clientName', setSort)}
                  data-testid="sort-client"
                >
                  <div>
                    <label>Client </label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                      data-testid="sort-icon-client"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('specialty', setSort)}
                  data-testid="sort-specialty"
                >
                  <div>
                    <label>Specialty</label>
                    <SortIcon
                      value={sort.key === 'specialty' ? sort.value : ''}
                      data-testid="sort-icon-specialty"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="sort-region"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon
                      value={sort.key === 'region' ? sort.value : ''}
                      data-testid="sort-icon-region"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('activities', setSort)}
                  data-testid="sort-activities"
                >
                  <div>
                    <label>Activities</label>
                    <SortIcon
                      value={sort.key === 'activities' ? sort.value : ''}
                      data-testid="sort-icon-activities"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('cpm', setSort)}
                  data-testid="sort-cpm"
                >
                  <div>
                    <label>CPM</label>
                    <SortIcon
                      value={sort.key === 'cpm' ? sort.value : ''}
                      data-testid="sort-icon-cpm"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('progress', setSort)}
                  data-testid="sort-progress"
                >
                  <div>
                    <label>Progress</label>
                    <SortIcon
                      value={sort.key === 'progress' ? sort.value : ''}
                      data-testid="sort-icon-progress"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('status', setSort)}
                  data-testid="sort-status"
                >
                  <div>
                    <label>Status</label>
                    <SortIcon
                      value={sort.key === 'status' ? sort.value : ''}
                      data-testid="sort-icon-status"
                    />
                  </div>
                </th>
                <th data-testid="empty-column"></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((project) => {
                        return (
                          <tr
                            key={project._id}
                            data-testid={`project-row-${project._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid="checkbox-table-row"
                            >
                              <input type="checkbox" />
                            </td>
                            <td data-testid={`project-name-${project._id}`}>
                              {project.client.clientName}
                            </td>
                            <td
                              data-testid={`project-specialty-${project._id}`}
                            >
                              {project.specialty}
                            </td>
                            <td data-testid={`project-region-${project._id}`}>
                              {project.region}
                            </td>
                            <td
                              data-testid={`project-activities-${project._id}`}
                            >
                              {project.activities.length}
                            </td>
                            <td
                              className="d-flex gap-2"
                              data-testid={`project-cpm-${project._id}`}
                            >
                              {project.cpms.slice(0, 1).map((el) => (
                                <Badge
                                  type="neutral"
                                  key={el._id}
                                  data-testid={`cpm-badge-${el._id}`}
                                >
                                  <Abbreviation>
                                    {el.firstName[0]}
                                    {el.lastName[0]}
                                  </Abbreviation>
                                  {el.firstName} {el.lastName}
                                </Badge>
                              ))}
                              {project.cpms.length > 1 && (
                                <Badge type="neutral">
                                  +{project.cpms.length - 1}
                                </Badge>
                              )}
                            </td>
                            <td data-testid={`project-progress-${project._id}`}>
                              <ProgressBar
                                value={calculateProjectProgress(project)}
                              />
                            </td>
                            <td data-testid={`project-status-${project._id}`}>
                              <Badge type={'success'}>Active</Badge>
                            </td>
                            <td data-testid={`project-actions-${project._id}`}>
                              <div
                                className="action-item"
                                data-testid="action-item"
                              >
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(project._id)}
                                  data-testid={`view-icon-${project._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className={`edit-icon`}
                                  onClick={() => openFormModal(project)}
                                  data-testid={`edit-icon-${project._id}`}
                                >
                                  <EditIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-item-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-item-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={projectsList.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
