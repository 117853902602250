import React, { useEffect, useState } from 'react';
import {
  CallIcon,
  DownloadIcon,
  EditIcon,
  LeftArrowIcon,
  MessageIcon,
} from '../../../icons';
import {
  ATag,
  Badge,
  CardContainer,
  Table,
  TitlePage,
  UserIcon,
  DragDropUpload,
  AddressDetails,
  UpdatePayrollForm,
  DocumentsListComponent,
  TabPageContainer,
} from '../../../components';
import { PayrollDetailsContainer } from './payroll-details.style';
import {
  AxiosInstance,
  formatDate,
  getDurationDifference,
} from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EUserRole, Payroll, PayrollExport } from '../../../backend/careo-api';
import ExcelJS from 'exceljs';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';

enum EPayrollDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
  EXPORTS = 'Exports',
}

const tabs = [
  EPayrollDetailsTabs.GENERAL,
  EPayrollDetailsTabs.CONTACT,
  EPayrollDetailsTabs.PAYROLL,
  EPayrollDetailsTabs.DOCUMENTS,
  EPayrollDetailsTabs.EXPORTS,
];

export const PayrollDetailsPage = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EPayrollDetailsTabs.GENERAL);
  const [payroll, setPayroll] = useState<Payroll | null>(null);
  const [exportsList, setExportsList] = useState<PayrollExport[]>([]);

  const isPayrollBelongsToUser =
    payroll?.user?._id === user?._id || user?.role === EUserRole.Admin;

  const { openModal, closeModal } = useModal();
  const onClickEdit = (payroll: Payroll) => {
    openModal({
      title: 'Update Payroll',
      component: (
        <UpdatePayrollForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getPayrollDetails();
            closeModal();
          }}
          selectedPayroll={payroll}
          data-testid="update-payroll-form"
        />
      ),
    });
  };

  const getPayrollDetails = () => {
    AxiosInstance.payrolls
      .payrollsControllerFindOne(id!)
      .then((response) => {
        setPayroll(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getExports = () => {
    AxiosInstance.payrollExports
      .payrollExportsControllerFindAll({ payrollId: id! })
      .then((response) => {
        setExportsList(response.data.items);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onGenerateExcel = (data: PayrollExport) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Add data to the worksheet
    worksheet.addRow([
      'CandidateID',
      'Title',
      'Forename',
      'Surname',
      'Timesheet ID',
      'Hours',
      'Pay Rate',
      'On Call Hours',
      'Pay Rate on call',
      'Pay amount',
      'Vat',
      'Payroll provider name',
      'Start Date',
      'End Date',
    ]);

    data.timesheets.map((timesheet) => {
      const candidate = timesheet.placement.candidate;

      const timings = timesheet.timings.map((el) => {
        const [hours1, minutes1] = el.startTime?.split(':') ?? [];
        const [hours2, minutes2] = el.endTime?.split(':') ?? [];
        const timesheetDate = new Date(el.date);
        const resultDate1 = new Date(
          timesheetDate.getFullYear(),
          timesheetDate.getMonth(),
          timesheetDate.getDate(),
          parseInt(hours1),
          parseInt(minutes1),
        );
        const resultDate2 = new Date(
          timesheetDate.getFullYear(),
          timesheetDate.getMonth(),
          timesheetDate.getDate(),
          parseInt(hours2),
          parseInt(minutes2),
        );
        const duration = Number(
          (
            getDurationDifference(resultDate1, resultDate2, el.breakTime) /
            1000 /
            60 /
            60
          ).toFixed(2),
        );

        return {
          duration,
          rate: timesheet.placement.job?.rate ?? 0,
          lineTotal: (timesheet.placement.job?.rate ?? 0) * duration,
        };
      });

      const totalHoursOnCall = Number(
        (
          timesheet.timings.reduce(
            (total, el) => total + (el?.callHours ?? 0),
            0,
          ) /
          (60 * 60)
        ).toFixed(2),
      );

      const rateOnCall = timesheet.placement.onCallRate ?? 0;

      const onCall = {
        totalHours: totalHoursOnCall,
        fee: rateOnCall,
        lineTotal: totalHoursOnCall * rateOnCall,
      };

      const totalNormalHours = timings.reduce(
        (total, el) => total + el.duration,
        0,
      );

      const totalNormalHoursLine = timings.reduce(
        (total, el) => total + el.lineTotal,
        0,
      );
      const totalLine = totalNormalHoursLine + onCall.lineTotal;
      const vat = Number(timesheet.placement?.job.vat ?? 0);

      worksheet.addRow([
        candidate?._id,
        candidate?.title,
        candidate?.firstName,
        candidate?.lastName,
        timesheet._id,
        totalNormalHours,
        timesheet.placement.job.rate ?? 0,
        onCall.totalHours,
        timesheet.placement.onCallRate ?? 0,
        totalLine,
        Number(((vat * totalLine) / 100).toFixed(2)),
        data.payroll.payrollProviderName,
        formatDate(timesheet.timings[0]?.date),
        formatDate(timesheet.timings.slice(-1)[0]?.date),
      ]);

      return timesheet;
    });

    // Generate a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      // Create a Blob from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export-' + data.referenceNumber + '.xlsx';
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getPayrollDetails();
    getExports();
  }, []);

  if (!payroll) {
    return <></>;
  }

  return (
    <>
      <PayrollDetailsContainer data-testid="payroll-details-container">
        <div className="payroll-header" data-testid="payroll-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="payroll-title">
            Detail Payroll Provider
          </TitlePage>
        </div>
        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <UserIcon
              lastName={payroll.payrollProviderName}
              firstName={payroll.payrollProviderName}
              entity="payroll"
              data-testid="user-icon"
            />{' '}
            <div className="payroll-info" data-testid="payroll-info">
              <div className="name" data-testid="payroll-provider-name">
                {payroll.payrollProviderName}
              </div>
              <div className="availability" data-testid="payroll-address-city">
                {payroll.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="module-contact-phone">
            <CallIcon />
            <div>{payroll.leadContactWorkPhoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="module-contact-email">
            <MessageIcon />
            <div>{payroll.leadContactEmail}</div>
          </div>
          <div className="module-contact " data-testid="module-contact-status">
            <Badge type={payroll.isProfileComplete ? 'success' : 'danger'}>
              {payroll.isProfileComplete ? 'Complete' : 'Incomplete'}
            </Badge>
          </div>
          <div
            className={`edit-container edit-icon ${!isPayrollBelongsToUser && 'disabled'}`}
            onClick={() => isPayrollBelongsToUser && onClickEdit(payroll)}
            data-testid="edit-container"
          >
            <EditIcon />
          </div>
        </CardContainer>
        <CardContainer
          className="payroll-information-container"
          data-testid="payroll-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                  data-testid={`tab-item-${el}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EPayrollDetailsTabs.GENERAL && (
                <>
                  <div
                    className="info-card"
                    data-testid="info-card-business-info"
                  >
                    <div
                      className="info-card-title"
                      data-testid="info-card-title"
                    >
                      Business Info
                    </div>
                    <hr />
                    <div
                      className="info-card-content row"
                      data-testid="info-card-content"
                    >
                      <div
                        className="col-md-6 row"
                        data-testid="info-item-name"
                      >
                        <div
                          className="info-item-title col-4"
                          data-testid="info-item-title-name"
                        >
                          Name
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="info-item-data-name"
                        >
                          {payroll.payrollProviderName}
                        </div>
                      </div>
                      <div
                        className="col-md-6 row"
                        data-testid="info-item-website"
                      >
                        <div
                          className="info-item-title col-4"
                          data-testid="info-item-title-website"
                        >
                          Website
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="info-item-data-website"
                        >
                          {payroll.website}
                        </div>
                      </div>
                      <div className="col-md-6 row" data-testid="info-item-vat">
                        <div
                          className="info-item-title col-4"
                          data-testid="info-item-title-vat"
                        >
                          VAT Number
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="info-item-data-vat"
                        >
                          {payroll.vatNumber}
                        </div>
                      </div>
                      <div
                        className="col-md-6 row"
                        data-testid="info-item-line-manager"
                      >
                        <div
                          className="info-item-title col-4"
                          data-testid="info-item-title-line-manager"
                        >
                          Line Manager
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="info-item-data-line-manager"
                        >
                          {payroll.user?.firstName} {payroll.user?.lastName}
                        </div>
                      </div>
                      <div
                        className="col-md-6 row"
                        data-testid="info-item-email"
                      >
                        <div
                          className="info-item-title col-4"
                          data-testid="info-item-title-email"
                        >
                          Contact Email
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="info-item-data-email"
                        >
                          {payroll.leadContactEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddressDetails
                    address={payroll.address}
                    data-testid="address-details"
                  />
                </>
              )}
              {selectedTab === EPayrollDetailsTabs.CONTACT && (
                <div className="info-card" data-testid="info-card-contact">
                  <div
                    className="info-card-title"
                    data-testid="info-card-title-contact"
                  >
                    Contact Details
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="info-card-content-contact"
                  >
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-title-contact"
                    >
                      <div className="info-item-title col-4">Title</div>
                      <div className="info-item-data col-8">
                        {payroll.title}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-first-name"
                    >
                      <div className="info-item-title col-4">First Name</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactFirstName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-last-name"
                    >
                      <div className="info-item-title col-4">Last Name</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactLastName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-job-title"
                    >
                      <div className="info-item-title col-4">Job Title</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactJobTitle}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-email-address"
                    >
                      <div className="info-item-title col-4">Email Address</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactEmail}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-mobile"
                    >
                      <div className="info-item-title col-4">Mobile Number</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactMobilePhoneNumber}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-work-phone"
                    >
                      <div className="info-item-title col-4">
                        Work Phone Number
                      </div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactWorkPhoneNumber}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-website"
                    >
                      <div className="info-item-title col-4">Website</div>
                      <div className="info-item-data col-8">
                        {payroll.website}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="info-item-fax">
                      <div className="info-item-title col-4">Fax Number</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactFax}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EPayrollDetailsTabs.PAYROLL && (
                <div className="info-card" data-testid="info-card-bank-account">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-bank-name"
                    >
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.name}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-account-name"
                    >
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.accountName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-branch"
                    >
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {payroll.address?.city}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-account-number"
                    >
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="info-item-type">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {payroll.employmentType}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-sort-code"
                    >
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EPayrollDetailsTabs.DOCUMENTS && (
                <>
                  <div className="info-card" data-testid="info-card-documents">
                    <div className="info-card-title">Payroll Documents</div>
                    <hr />
                    <DocumentsListComponent
                      documents={[]}
                      onClickDelete={() => {}}
                      getDocuments={() => {}}
                      data-testid="documents-list"
                    />
                  </div>
                  <div className="info-card" data-testid="info-card-upload">
                    <DragDropUpload onUpload={(files: File[]) => ({}) as any} />
                  </div>
                </>
              )}
              {selectedTab === EPayrollDetailsTabs.EXPORTS && (
                <Table data-testid="exports-table">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Type</th>
                      <th>Generated</th>
                      <th>Duration</th>
                      <th>Status</th>
                      <th className="action-th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exportsList.map((el) => (
                      <tr key={el._id}>
                        <td>export-{el.referenceNumber}.xlsx</td>
                        <td>xlsx</td>
                        <td>{formatDate(el.createdDate)}</td>
                        <td>7 days</td>
                        <td>
                          <div className="module-contact ">
                            <Badge type={'success'}>{el.status}</Badge>
                          </div>
                        </td>
                        <td>
                          <div className="action-item">
                            <div
                              className="download-icon"
                              onClick={() => onGenerateExcel(el)}
                              data-testid="download-icon"
                            >
                              <DownloadIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </PayrollDetailsContainer>
    </>
  );
};
