import React from 'react';
import { HostedFile, Project } from '../../backend/careo-api';
import { DocumentsListComponent } from '../document';
import { Abbreviation, Badge, DragDropUpload } from '../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  uploadProjectFiles,
} from '../../utils';
import { toast } from 'react-toastify';

type ProjectDetailsLegalProps = {
  project: Project;
  documents: HostedFile[];
  getDocuments: () => void;
};

export const ProjectDetailsLegal = ({
  project,
  getDocuments,
  documents,
}: ProjectDetailsLegalProps) => {
  const handleFileChange = async (files: File[]) => {
    await uploadProjectFiles(project._id, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getDocuments();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDelete = async (documentId: string) => {
    await AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  return (
    <>
      <div className="info-card" data-testid="contacts-info-card">
        <div className="info-card-title" data-testid="contacts-info-title">
          Contacts
        </div>
        <hr />
        <div className="row" data-testid="contacts-info-row">
          <div className="col-md-6" data-testid="contacts-info-col-left">
            <div
              className="info-card-content row"
              data-testid="contacts-info-left"
            >
              <div className="row" data-testid="client-name">
                <div
                  className="info-item-title col-4"
                  data-testid="client-name-title"
                >
                  Contact
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="client-name-data"
                >
                  {project.legalContact ? (
                    <Badge type="neutral">
                      <Abbreviation>
                        {project.legalContact.firstName[0]}
                        {project.legalContact.lastName[0]}
                      </Abbreviation>
                      {project.legalContact.firstName}{' '}
                      {project.legalContact.lastName}
                    </Badge>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-card" data-testid="documents-info-card">
        <div className="info-card-title" data-testid="documents-info-title">
          Documents
        </div>
        <hr />
        <DocumentsListComponent
          documents={documents}
          onClickDelete={onClickDelete}
          getDocuments={getDocuments}
          data-testid="documents-list"
        />
        <DragDropUpload
          onUpload={handleFileChange}
          data-testid="drag-drop-upload"
        />
      </div>
    </>
  );
};
