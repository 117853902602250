import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AddFinancialLeadDto,
  ELeadDurationType,
  Lead,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { financialLeadSchema } from '../../../utils/validators/leads.validator';
import { useForm } from 'react-hook-form';
import { Badge, Button, FieldText, Select } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';
import { leadDurationList } from '../../../constants';

export type FinancialFormStepProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdLead: Lead;
  setLead: Dispatch<SetStateAction<Lead | undefined>>;
  onSuccess: () => void;
  showModalActionBtns?: boolean;
};

export const calculateMarginWithPercentage = (
  revenue: number,
  cost: number,
) => {
  if (revenue && cost) {
    const marginValue = revenue - cost;
    const marginPercentage = (marginValue / revenue) * 100;
    return {
      value: marginValue,
      percentage: Number.isInteger(marginPercentage)
        ? marginPercentage
        : marginPercentage.toFixed(2),
    };
  }
  return undefined;
};

export function calculateBenchmark(
  cost: number,
  duration: number,
  unit: ELeadDurationType,
) {
  if (!cost || !duration || !unit) {
    return undefined;
  }

  const daysInUnit: { [key in ELeadDurationType]: number } = {
    Day: 1,
    Week: 7,
    Month: 30,
  };
  const totalDays = duration * daysInUnit[unit];
  const benchmark = (cost / totalDays) * Math.min(totalDays, 90);
  return Math.round(benchmark * 100) / 100;
}

export const LeadFinancialFormStep = ({
  step,
  setStep,
  createdLead,
  setLead,
  onSuccess: onSuccess,
  showModalActionBtns,
}: FinancialFormStepProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,

    watch,
  } = useForm<AddFinancialLeadDto>({
    resolver: yupResolver(financialLeadSchema),
  });

  const { revenue, cost, durationType, durationValue } = watch();

  const margin = useMemo(() => {
    return calculateMarginWithPercentage(revenue, cost);
  }, [cost, revenue]);

  const benchmark = useMemo(() => {
    return calculateBenchmark(cost, durationValue, durationType);
  }, [cost, durationValue, durationType]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmitFinancialLead = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto: AddFinancialLeadDto = {
      ...values,
      durationValue: Number(values.durationValue),
      revenue: Number(values.revenue),
      cost: Number(values.cost),
    };

    await AxiosInstance.leads
      .leadsControllerAddFinancialLeadyId(createdLead._id, requestDto)
      .then((response) => {
        setLead(response.data);
        if (isWithExist) {
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('Financial Lead saved successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdLead) {
      reset({
        ...createdLead,
        startDate: createdLead?.startDate?.split('T')?.[0] ?? '',
      });
    }
  }, [createdLead]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            register={register('startDate')}
            error={errors.startDate}
            data-testid="startDate-input"
            type="date"
          />
        </div>
        <div className="col-md-2">
          <FieldText
            placeholder="Enter here ..."
            label="Duration"
            required
            register={register('durationValue')}
            error={errors.durationValue}
            type="number"
          />
        </div>
        <div className="col-md-4">
          <Select
            placeholder="Enter here ..."
            label="&nbsp;"
            options={leadDurationList.map((el) => ({
              label: el,
              value: el,
            }))}
            register={register('durationType')}
            control={control}
            error={errors.durationType}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Revenue"
            required
            register={register('revenue')}
            error={errors.revenue}
            data-testid="revenue-input"
            type="currency"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Cost"
            required
            register={register('cost')}
            error={errors.cost}
            data-testid="cost-input" // Added test ID for Cost field
            type="currency"
          />
        </div>
        <div className="col-md-6 margin-container">
          <FieldText
            label="Margin"
            required
            value={margin?.value}
            type="currency"
            data-testid="margin-input"
            disabled
          />
          <Badge type="success">{margin?.percentage}%</Badge>
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Cashflow Benchmark 90 Days"
            required
            type="currency"
            data-testid="benchmark-input"
            value={benchmark}
            disabled
          />
        </div>
      </div>

      <div className="form-actions stepper" data-testid="form-actions">
        {showModalActionBtns ? (
          <>
            <Button
              onClick={() => closeModal()}
              data-testid="button-cancel"
              type="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit(() => onSubmitFinancialLead(true))}
              disabled={isSubmitting}
              data-testid="button-update"
            >
              Update
            </Button>
          </>
        ) : (
          <>
            <div className="left-container">
              <Button
                onClick={handleSubmit(() => onSubmitFinancialLead(true))}
                type="primary"
                variant="outlined"
                data-testid="save-exit-button"
              >
                Save & Exit
              </Button>
              <Button
                type="danger"
                variant="outlined"
                onClick={() => closeModal()}
                data-testid="cancel-button"
              >
                Close
              </Button>
            </div>
            <div className="right-container">
              <Button onClick={() => setStep(1)}>
                <LeftArrowIcon /> Back
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit(() => onSubmitFinancialLead(false))}
                data-testid="next-button" // Added test ID for Next button
                disabled={isSubmitting}
              >
                Next <RightArrowIcon />
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
