export const PagesUrls = {
  AUTHENTICATION: { Login: '/login' },
  TRUSTED_CLIENT: { Index: '/trusts' },
  VACANCIES: {
    Index: 'vacancies',
    Details: '/vacancies/:id',
    Applications: 'vacancies/:id/applications',
  },
  PAYROLLS: { Index: '/payrolls', Details: '/payrolls/:id' },
  PLACEMENTS: { Index: '/placements', Details: '/placements/:id' },
  CALENDAR: { Index: '/calendar', Details: '/calendar/:id' },
  MESSAGES: {
    Index: '/messages',
    Candidate: '/messages/candidate',
    Client: '/messages/client',
    WildCard: '/messages/*',
  },
  EMAILS: {
    Index: '/emails/:emailType',
    EmailDetails: '/emails/:emailType/:emailId',
    WildCard: '/emails/*',
  },
  SETTING: { Index: '/settings', Type: '/settings/:type' },
  TIMESHEET: {
    Client: '/timesheet/:token/recruiter',
    Download: '/timesheet/:token/recruiter/download',
    Candidate: '/timesheet/:token/candidate',
  },
  OFFER: {
    Client: '/offer/:token/client',
    Candidate: '/offer/:token/candidate',
    Recruiter: '/offer/:placementId/recruiter',
  },
  REPORTING: {
    index: 'reporting',
  },
};
