import React, { useMemo } from 'react';
import styled from 'styled-components';
// Assuming similar user icon component for lead owners
// Replace with actual icons used in your project
// Assuming a similar utility function for formatting dates
import { Abbreviation, Badge } from '../badge';
import { breakpoint } from '../../../constants';
import { handleDragStart } from './pipeline';
import { ELeadStatus, ELeadType, Lead } from '../../../backend/careo-api';
import { leadTypeValueType } from '../../lead/view-lead.component';
import { Button } from '../buttons';
import { CancelIcon, CheckIcon } from '../../../icons';
import {
  AxiosInstance,
  ERoute,
  formatCurrency,
  formatDate,
} from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { calculateMarginWithPercentage } from '../../lead/lead-form/lead-financial-form-step';
import { useModal } from '../../../contexts/side-modal.context';
import { NewNoteLeadForm } from '../../lead/new-note-lead-form.component';

// Styled Components for the LeadCardPipeline
const LeadCardPipelineContainer = styled.div<{ type: ELeadType }>`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;

  border: 1px solid
    ${({ type }) => (type === ELeadType.New ? '#0CAF60' : '#ffc008')};

  .top-container {
    .date-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .date {
      font-size: 14px;
      font-weight: 600;
    }
    .owner-icons {
      display: flex;
      gap: 2px;
      align-items: center;

      .abbreviation-container {
        width: 24px;
        height: 24px;
      }
      .archive-button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 20px;
        }
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        }
      }
    }

    .region {
      font-size: 12px;
      color: #a0aec0;
    }
  }

  .status-badges {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .overview {
    font-size: 12px;
  }

  .action-buttons {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    button {
      flex: 1;
      padding: 8px 17px;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 5px;
    .top-container {
      .date-container {
      }
      .date {
        font-size: 8px;
      }

      .region {
        font-size: 8px;
      }
    }

    .status-badges {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .overview {
      font-size: 8px;
    }
  }
`;

type LeadCardPipelineProps = {
  item: Lead;
  listIndex: number;
  itemIndex: number;
  openViewModal: (data: Lead) => void;
  getLeads: () => void;
  onDeleteConfirm: (data: Lead) => void;
};

export const LeadCardPipeline = ({
  item,
  listIndex,
  itemIndex,
  openViewModal,
  onDeleteConfirm,
  getLeads,
}: LeadCardPipelineProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const createProject = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerCreateProjectViaLead({ leadId: lead._id })
      .then((result) => {
        const project = result.data;
        toast.success('General Project saved successfully');
        navigate(`/${ERoute.PROJECTS}/${project._id}`);
      })
      .catch((e) => {
        toast.error(
          'Could not create a project for this lead, please try again or contact Admin',
        );
      });
  };

  const onClickCreateNote = () => {
    openModal({
      title: 'New Note',
      component: (
        <NewNoteLeadForm
          lead={item}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getLeads();
            closeModal();
          }}
          data-testid="add-note-form"
        />
      ),
    });
  };

  const margin = useMemo(() => {
    return calculateMarginWithPercentage(item.revenue ?? 0, item.cost ?? 0);
  }, [item]);

  return (
    <LeadCardPipelineContainer
      key={item._id}
      draggable="true"
      onDragStart={(e) => handleDragStart(e, listIndex, itemIndex)}
      onDoubleClick={() => openViewModal(item)}
      type={item.type}
    >
      <div className="top-container">
        <div className="date-container">
          <div className="date">{item.hospital.clientName}</div>
          <div className="owner-icons">
            <Abbreviation
              title={`${item.cpm?.firstName} ${item.cpm?.lastName}`}
            >
              {item.cpm?.firstName[0]}
              {item.cpm?.lastName[0]}
            </Abbreviation>
            <div
              className="archive-button"
              onClick={() => onDeleteConfirm(item)}
            >
              <CancelIcon />
            </div>
          </div>
        </div>
        <div className="region" data-testid="region">
          {item.specialty}
        </div>
      </div>
      <div className="status-badges">
        <Badge data-testid="lead-type" type={leadTypeValueType(item.type)}>
          {item.type}
        </Badge>
      </div>

      <div className="status-badges">
        <Badge type="neutral" data-testid={`cpm-badge`}>
          <Abbreviation>
            {item.clientDepartment?.firstNameContact[0]}
            {item.clientDepartment?.lastNameContact[0]}
          </Abbreviation>
          {item.clientDepartment?.firstNameContact}{' '}
          {item.clientDepartment?.lastNameContact}
        </Badge>
      </div>
      <div className="overview" data-testid="overview">
        {[ELeadStatus.NewOpportunity, ELeadStatus.MeetingScheduled].includes(
          item.status,
        ) ? (
          <></>
        ) : (
          <>
            <div>
              <div>
                Revenue :<span> {formatCurrency(item.revenue)}</span>
              </div>
              <div>
                Cost :<span> {formatCurrency(item.cost)}</span>
              </div>
              <div>
                Margin :
                <span>
                  {' '}
                  {formatCurrency(margin?.value)} ({margin?.percentage}%)
                </span>
              </div>
              <div>
                Start Date :{' '}
                <span>{item.startDate ? formatDate(item.startDate) : '-'}</span>
              </div>
              <div>
                Proposed Date :{' '}
                <span>
                  {item.proposedDate ? formatDate(item.proposedDate) : '-'}
                </span>
              </div>
              <div>
                Duration :
                <span>
                  {' '}
                  {item.durationValue} {item.durationType}
                </span>
              </div>
            </div>
          </>
        )}
      </div>

      {item.status === ELeadStatus.MeetingScheduled && (
        <div>
          <Button
            className="fit-width"
            type="primary"
            variant="outlined"
            onClick={() => onClickCreateNote()}
          >
            Add new Note
          </Button>
        </div>
      )}

      {(item.status === ELeadStatus.ContractSigned ||
        item.status === ELeadStatus.ContractReceived ||
        item.status === ELeadStatus.OperationalGoLive) && (
        <div>
          {item.project ? (
            <Button
              className="fit-width"
              type="neutral"
              variant="contained"
              onClick={() =>
                window.open(
                  `/${ERoute.PROJECTS}/${item.project?._id ?? item.project}`,
                  '_blank',
                )
              }
            >
              View Project
            </Button>
          ) : (
            <div className="action-buttons">
              <Button
                className="fit-width"
                type="danger"
                onClick={() => onDeleteConfirm(item)}
              >
                <CancelIcon /> Archive
              </Button>{' '}
              <Button
                className="fit-width"
                type="success"
                onClick={() => createProject(item)}
              >
                <CheckIcon /> Live
              </Button>
            </div>
          )}
        </div>
      )}

      <div className="status-badges" data-testid="additional-status-badges">
        {item.trust && (
          <Badge type="neutral" data-testid="trust-hospital">
            {item.trust.name}
          </Badge>
        )}
        <Badge type="neutral" data-testid="specialty">
          {item.region}
        </Badge>
      </div>
    </LeadCardPipelineContainer>
  );
};
