import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const LeadContainerPage = styled.div`
  .target-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-container {
    }
    .right-container {
      display: flex;
      align-items: center;
      gap: 40px;
      flex: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
      .budget-container {
        font-size: 12px;
        font-weight: 700;
        .title {
        }
        .numbers {
          .green {
            color: #0caf60;
          }
        }
        .edit-icon {
          background-color: #2f78ee;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-radius: 10px;
          cursor: pointer;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  .leads-list-container {
    .header-leads-list-container {
      .right-container {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .target-container {
      flex-direction: column;
      .left-container {
      }
      .right-container {
        gap: 20px;

        .budget-container {
          font-size: 8px;
          .title {
          }
          .numbers {
          }
          .edit-icon {
            width: 22px;
            height: 22px;
            gap: 5px;
            cursor: pointer;
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    .leads-list-container {
      .header-leads-list-container {
        flex-direction: column;
        justify-content: flex-start;
        .left-container {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: flex-start;
          width: 100%;
        }
        .right-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          > div > svg {
            width: unset;
            height: unset;
          }
        }
      }
    }
  }
`;
