import React, { useEffect, useState } from 'react';
import { Activity, ActivityProjectDto, Project } from '../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useModal } from '../../contexts/side-modal.context';
import { Button, FieldText, FormContainer, Select } from '../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  singleActivitySchema,
} from '../../utils';
import {
  projectActivityRateTypeList,
  projectActivityTypeList,
  subSpecialtiesList,
} from '../../constants';
import { toast } from 'react-toastify';

type UpdateActivityFormProps = {
  selectedActivity: Activity;
  selectedProject: Project;
  getProjectDetails: () => void;
};

export const UpdateActivityForm = ({
  selectedProject,
  selectedActivity,
  getProjectDetails,
}: UpdateActivityFormProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
  } = useForm<ActivityProjectDto>({
    resolver: yupResolver(singleActivitySchema as any),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitActivityProject = async () => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto: ActivityProjectDto = {
      ...values,
    };

    await AxiosInstance.projects
      .projectsControllerEditActivity(
        selectedProject._id,
        selectedActivity._id,
        requestDto,
      )
      .then(() => {
        getProjectDetails();
        toast.success('Activity saved successfully');
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({
      ...selectedActivity,
      clientDepartmentId: selectedActivity.clientDepartment?._id,
    });
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <div className="row" data-testid={`overview-row`}>
        <div className="col-md-6" data-testid={`sub-specialty-select`}>
          <Select
            placeholder="Enter here ..."
            label="Sub specialty"
            required
            register={register(`subSpecialty`)}
            error={errors.subSpecialty}
            options={subSpecialtiesList.map((el) => ({
              label: el,
              value: el,
            }))}
            control={control}
          />
        </div>
        <div className="col-md-6" data-testid={`activity-type-select`}>
          <Select
            placeholder="Enter here ..."
            label="Activity type"
            required
            register={register(`activityType`)}
            error={errors.activityType}
            options={projectActivityTypeList.map((el) => ({
              label: el,
              value: el,
            }))}
            control={control}
          />
        </div>
        <div className="col-md-6" data-testid={`start-date-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="Activity Start Date"
            required
            register={register(`startDate`)}
            error={errors.startDate}
            type="date"
          />
        </div>
        <div className="col-md-6" data-testid={`end-date-select`}>
          <FieldText
            placeholder="Enter here ..."
            label="Activity End Date"
            required
            register={register(`endDate`)}
            error={errors.endDate}
            type="date"
          />
        </div>
        <div className="col-md-12">
          <Select
            placeholder="Enter here ..."
            label="Activity Contact"
            data-testid={`activity-contact-select`}
            options={selectedProject.client.departments.map((el) => ({
              label: el.firstNameContact + ' ' + el.lastNameContact,
              value: el._id,
            }))}
            required
            register={register(`clientDepartmentId`)}
            error={errors.clientDepartmentId}
            control={control}
          />
        </div>
        <div className="col-md-3" data-testid={`type-select`}>
          <Select
            placeholder="Enter here ..."
            label="Type"
            required
            register={register(`rateType`)}
            error={errors.rateType}
            options={projectActivityRateTypeList.map((el) => ({
              label: el,
              value: el,
            }))}
            control={control}
          />
        </div>
        <div className="col-md-3" data-testid={`rate-field`}>
          <FieldText
            label="Rate"
            placeholder="Enter here ..."
            required
            register={register(`rate`)}
            error={errors.rate}
            type="currency"
          />
        </div>
        <div className="col-md-3" data-testid={`template-field`}>
          <FieldText
            label="Template"
            placeholder="Enter here ..."
            required
            register={register(`template`)}
            error={errors.template}
            type="number"
          />
        </div>
        <div className="col-md-3" data-testid={`bcv-field`}>
          <FieldText
            label="BCV"
            placeholder="Enter here ..."
            required
            register={register(`bcv`)}
            error={errors.bcv}
            type="currency"
          />
        </div>
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmitActivityProject)}
          disabled={isSubmitting}
          data-testid="button-create"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
