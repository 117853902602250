import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Client,
  CreateGeneralLeadDto,
  Lead,
  Trust,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { generalLeadSchema } from '../../../utils/validators/leads.validator';
import { useForm } from 'react-hook-form';
import { Button, Select } from '../../ui';
import {
  leadTypesList,
  regionsWithCounties,
  specialtiesList,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { RightArrowIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';
import { useAuth } from '../../../contexts/auth.context';

type GeneralFormStepProps = {
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  setStep: Dispatch<SetStateAction<number>>;
  createdLead: Lead | undefined;
  setCreatedLead: Dispatch<SetStateAction<Lead | undefined>>;
  getLeads: () => void;
  isStepper: boolean;
};

export const GeneralFormStep = ({
  usersList,
  trustsList,
  clientsList,
  setStep,
  createdLead,
  setCreatedLead,
  getLeads,
  isStepper,
}: GeneralFormStepProps) => {
  const { user } = useAuth();
  const { closeModal } = useModal();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<CreateGeneralLeadDto>({
    resolver: yupResolver(generalLeadSchema),
  });

  const trustId = watch('trustId');
  const hospitalId = watch('hospitalId');

  const hospitalOptions = useMemo(() => {
    if (trustId) {
      return clientsList.filter((el) => el.trust?._id === trustId);
    }
    return clientsList;
  }, [clientsList, trustId]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmitGeneralLead = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto = {
      ...values,
    };

    const leadRequest = () =>
      createdLead
        ? AxiosInstance.leads.leadsControllerUpdateById(
            createdLead._id,
            requestDto,
          )
        : AxiosInstance.leads.leadsControllerCreate(requestDto);

    await leadRequest()
      .then((response) => {
        setCreatedLead(response.data);
        if (isWithExist || !createdLead) {
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('General Lead saved successfully');
        getLeads();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdLead) {
      reset({
        ...createdLead,
        cpmId: createdLead.cpm._id,
        hospitalId: createdLead.hospital._id,
        trustId: createdLead?.trust?._id ?? undefined,
        clientDepartmentId: createdLead.clientDepartment._id ?? undefined,
      });
    } else {
      reset({
        cpmId: user?._id,
      });
    }
  }, [createdLead]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="CPM"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            register={register('cpmId')}
            control={control}
            required
            error={errors.cpmId}
            disabled
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Type"
            required
            options={leadTypesList.map((value) => ({
              label: value,
              value: value,
            }))}
            register={register('type')}
            control={control}
            error={errors.type}
          />
        </div>
        <div className="col-md-12">
          <Select
            placeholder="Enter here ..."
            label="Specialty"
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            register={register('specialty')}
            control={control}
            required
            error={errors.specialty}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Region"
            options={regionsWithCounties.map((el) => ({
              value: el.region,
              label: el.region,
            }))}
            register={register('region')}
            control={control}
            required
            error={errors.region}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Trust"
            options={trustsList.map((el) => ({
              value: el._id,
              label: el.name,
            }))}
            register={register('trustId')}
            control={control}
            error={errors.trustId}
          />
        </div>

        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Hospital"
            options={hospitalOptions.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            register={register('hospitalId')}
            control={control}
            required
            error={errors.hospitalId}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Contact"
            options={
              hospitalOptions
                .find((el) => el._id === hospitalId)
                ?.departments.map((el) => ({
                  label: el.firstNameContact + ' ' + el.lastNameContact,
                  value: el._id,
                })) ?? []
            }
            register={register('clientDepartmentId')}
            control={control}
            required
            error={errors.clientDepartmentId}
            disabled={!hospitalId}
          />
        </div>
      </div>
      {isStepper ? (
        <div className="form-actions stepper" data-testid="form-actions">
          <div className="left-container">
            <Button
              onClick={handleSubmit(() => onSubmitGeneralLead(true))}
              type="primary"
              variant="outlined"
              data-testid="save-exit-button"
            >
              Save & Exit
            </Button>
            <Button
              type="danger"
              variant="outlined"
              onClick={() => closeModal()}
              data-testid="cancel-button"
            >
              Close
            </Button>
          </div>
          <div className="right-container">
            <Button
              type="primary"
              onClick={handleSubmit(() => onSubmitGeneralLead(false))}
              data-testid="next-button"
              disabled={isSubmitting}
            >
              Next <RightArrowIcon />
            </Button>
          </div>
        </div>
      ) : (
        <div className="form-actions" data-testid="form-actions">
          <Button
            type="primary"
            variant="outlined"
            onClick={() => closeModal()}
            data-testid="cancel-button"
          >
            Close
          </Button>

          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitGeneralLead(false))}
            data-testid="create-button" // Added test ID for Next button
            disabled={isSubmitting}
          >
            Create
          </Button>
        </div>
      )}
    </>
  );
};
