import React from 'react';
import { ConfirmModalContainer } from '../../ui/modals/confirm-modal/confirm-modal.style';
import { Button, TitlePage } from '../../ui';
import { CancelIcon } from '../../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDate,
} from '../../../utils';
import { EAiJobShift } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type AddAvailabilityConfirmModalProps = {
  slotToAdd:
    | {
        day: Date;
        shift: EAiJobShift;
      }
    | undefined;
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddAvailabilityConfirmModal = ({
  slotToAdd,
  onSuccess = () => {},
  onCancel = () => {},
}: AddAvailabilityConfirmModalProps) => {
  const { id } = useParams();

  const isFull = slotToAdd?.shift === EAiJobShift.FullDay;
  const isDay = slotToAdd?.shift === EAiJobShift.Day;
  // const isNight = slotToAdd?.shift === EAiJobShift.Night;

  const onClickAddNewAvailability = (
    from: Date,
    availabilityTime: EAiJobShift,
  ) => {
    AxiosInstance.availability
      .availabilityControllerAddAvailability({
        to: undefined,
        candidateId: id!,
        from: from.toJSON(),
        availabilityTime,
      })
      .then(() => {
        toast.success('Availability added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  if (!slotToAdd) {
    return <></>;
  }

  return (
    <ConfirmModalContainer className="availability">
      <div className="confirmation-container">
        <div className="confirmation-header">
          <TitlePage>Add New Availability</TitlePage>
          <CancelIcon onClick={onCancel} />
        </div>
        <div className="confirmation-body">
          Are you sure you want to set{' '}
          <b>{formatDate(slotToAdd?.day?.toJSON() ?? '')} </b> as your new
          availability?
        </div>
        <div className="confirmation-footer">
          {/* <Button
            type="primary"
            onClick={() =>
              isFull &&
              onClickAddNewAvailability(slotToAdd?.day, EAiJobShift.FullDay)
            }
            className="full"
            disabled={!isFull}
          >
            Add
          </Button> */}
          <Button
            type="primary"
            onClick={() =>
              (isFull || isDay) &&
              onClickAddNewAvailability(slotToAdd?.day, EAiJobShift.Day)
            }
            className="full"
            disabled={!isFull && !isDay}
          >
            Add Availability
          </Button>
          {/* <Button
            type="primary"
            onClick={() =>
              (isFull || isNight) &&
              onClickAddNewAvailability(slotToAdd?.day, EAiJobShift.Night)
            }
            className="night"
            disabled={!isFull && !isNight}
          >
            Night
          </Button> */}
          <Button type="primary" onClick={() => onCancel()} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmModalContainer>
  );
};
