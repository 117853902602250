import React, { useState } from 'react';
import { TitleCard, Button, Textarea, FormContainer } from '../ui';
import { Lead } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';

type NewNoteLeadFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  lead: Lead;
};

export const NewNoteLeadForm = ({
  onCancel,
  onSuccess,
  lead,
}: NewNoteLeadFormProps) => {
  const [newNote, setNewNote] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    if (!newNote) {
      return;
    }

    setIsSubmitting(true);
    AxiosInstance.leads
      .leadsControllerAddNoteByLeadId(lead._id, {
        text: newNote,
      })
      .then(() => {
        toast.success('Lead Note updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setIsSubmitting(false);
  };

  return (
    <FormContainer data-testid="form-container">
      <TitleCard data-testid="title-card">Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
        data-testid="note-textarea"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => onCancel()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onClickSubmit}
          data-testid="submit-button"
          disabled={isSubmitting}
        >
          Note
        </Button>
      </div>
    </FormContainer>
  );
};
