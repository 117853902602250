import React, { useEffect, useMemo, useState } from 'react';
import {
  formatCurrency,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  getWeekNumber,
  onSelectSort,
} from '../../utils';
import { Badge, Pagination, Table } from '../ui';
import { EditIcon, EyeIcon, SortIcon } from '../../icons';
import { generateWeeks, RotasItem } from './project-details-rotas.component';
import {
  applicationApprovalStatusList,
  timesheetStatusList,
} from '../../constants';
import { Project, Timing } from '../../backend/careo-api';

type ProjectPlacementsListProps = {
  isLoading: boolean;
  rotasItems: RotasItem[];
  project: Project;
  selectedYear: number;
};

const itemsPerPage = 8;

export const formatExpense = (
  timesheetExpense: number,
  allocatedExpense: number,
  prefix?: string,
) => {
  return (
    <div>
      {prefix}{' '}
      <span>
        <span>{formatCurrency(timesheetExpense)}</span> /{' '}
        <span>{formatCurrency(allocatedExpense)}</span>
      </span>
    </div>
  );
};

export const ProjectPlacementsList = ({
  isLoading,
  rotasItems,
  project,
  selectedYear,
}: ProjectPlacementsListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rotasList, setRotasList] = useState<RotasItem[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const calculateTotalPayrollCost = (timing: Timing) => {
    const {
      accommodationExpensesByCandidate,
      travelExpenses,
      foodExpenses,
      otherExpenses,
    } = timing;

    console.log({
      accommodationExpensesByCandidate,
      travelExpenses,
      foodExpenses,
      otherExpenses,
    });

    return (
      accommodationExpensesByCandidate +
      travelExpenses +
      foodExpenses +
      otherExpenses
    );
  };

  const calculateTotalWorkerCost = (timing: Timing) => {
    const { accommodationExpensesByBusiness } = timing;

    return calculateTotalPayrollCost(timing) + accommodationExpensesByBusiness;
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(rotasList, currentPage, itemsPerPage);
  }, [rotasList, currentPage]);

  useEffect(() => {
    setRotasList(rotasItems);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(rotasItems.length, itemsPerPage));
  }, [rotasItems, sort]);

  const yearWeeks = useMemo(() => {
    return generateWeeks(selectedYear);
  }, [selectedYear]);

  return (
    <>
      <div className="data-table-container" data-testid="data-table-container">
        <Table>
          <thead>
            <tr>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('month', setSort)}
                data-testid="month-column"
              >
                <div>
                  <label>Month</label>
                  <SortIcon value={sort.key === 'month' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('week', setSort)}
                data-testid="week-column"
              >
                <div>
                  <label>Week</label>
                  <SortIcon value={sort.key === 'week' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Day</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Date</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Client</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Specialty</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Week Com</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('day', setSort)}
                data-testid="day-column"
              >
                <div>
                  <label>Week End</label>
                  <SortIcon value={sort.key === 'day' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('status', setSort)}
                data-testid="status-column"
              >
                <div>
                  <label>Activity Type</label>
                  <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('tag', setSort)}
                data-testid="tag-column"
                style={{ minWidth: '200px' }}
              >
                <div>
                  <label>Role</label>
                  <SortIcon value={sort.key === 'tag' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('cpm', setSort)}
                data-testid="cpm-column"
              >
                <div>
                  <label>Status</label>
                  <SortIcon value={sort.key === 'cpm' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('type', setSort)}
                data-testid="type-column"
              >
                <div>
                  <label>Worker Name</label>
                  <SortIcon value={sort.key === 'type' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('region', setSort)}
                data-testid="region-column"
              >
                <div>
                  <label>Rate</label>
                  <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('region', setSort)}
                data-testid="region-column"
              >
                <div>
                  <label>Charge</label>
                  <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('trustHospital', setSort)}
                data-testid="trust-hospital-column"
                style={{ minWidth: '250px' }}
              >
                <div>
                  <label>Accommodation</label>
                  <SortIcon
                    value={sort.key === 'trustHospital' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('specialty', setSort)}
                data-testid="specialty-column"
              >
                <div>
                  <label>Travel</label>
                  <SortIcon
                    value={sort.key === 'specialty' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Food</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Other Expenses</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Timesheet Confirmation</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Payment Frequency</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Payment Type</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Total Payroll Cost</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Total Worker Cost</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Confirmation Sent</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Invoice Received</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>

              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Invoice Paid</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Service Log</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Notes</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                className="text-center large-cell"
                data-testid="action-column"
              >
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={100}
                  className="text-center"
                  data-testid="loading-row"
                >
                  Loading ...
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData.map((rotasItem) => {
                      const applicationStatus =
                        applicationApprovalStatusList?.find(
                          (el) => el.value === rotasItem.status,
                        );

                      const timesheetStatus = timesheetStatusList.find(
                        (el) => el.value === rotasItem.timesheet.status,
                      );

                      const rotasWeek = yearWeeks.find(
                        (week) =>
                          week.startDate <= rotasItem.day &&
                          week.endDate >= rotasItem.day,
                      );

                      const timing = rotasItem.timesheet.timings?.[0] ?? {
                        accommodationExpensesByBusiness: 0,
                        accommodationExpensesByCandidate: 0,
                        travelExpenses: 0,
                        foodExpenses: 0,
                        otherExpenses: 0,
                        chargingUnitCount: 0,
                      };
                      return (
                        <tr
                          key={rotasItem._id}
                          data-testid={`placement-row-${rotasItem._id}`}
                        >
                          <td className="text-center text-nowrap">
                            {rotasItem.day.toLocaleString('default', {
                              month: 'long',
                            })}
                          </td>
                          <td className="text-center text-nowrap">
                            {getWeekNumber(rotasItem.day)}
                          </td>

                          <td className="text-center text-nowrap">
                            {rotasItem.day.toLocaleString('default', {
                              weekday: 'long',
                            })}
                          </td>
                          <td className="text-center text-nowrap">
                            {rotasItem.day.toLocaleString('default', {
                              dateStyle: 'short',
                            }) ?? 'N/A'}
                          </td>

                          <td className="text-center text-nowrap">
                            {rotasItem.placement?.job?.client?.firstName +
                              ' ' +
                              rotasItem.placement?.job?.client?.lastName}
                          </td>

                          <td className="text-center text-nowrap">
                            {rotasItem.placement?.job?.specialty ?? 'N/A'}
                          </td>

                          <td className="text-center text-nowrap">
                            {rotasWeek?.startDate.toLocaleString('default', {
                              dateStyle: 'short',
                            }) ?? 'N/A'}
                          </td>

                          <td className="text-center text-nowrap">
                            {rotasWeek?.endDate.toLocaleString('default', {
                              dateStyle: 'short',
                            }) ?? 'N/A'}
                          </td>
                          <td>{rotasItem.activityType}</td>
                          <td>{rotasItem.role}</td>
                          <td>
                            {applicationStatus && (
                              <Badge
                                type={applicationStatus?.type}
                                data-testid={`type-badge-${rotasItem._id}`}
                              >
                                {applicationStatus?.label}
                              </Badge>
                            )}
                          </td>
                          <td className="text-center">
                            {rotasItem.workerName}
                          </td>
                          <td className="text-center">
                            {formatCurrency(rotasItem.rate)}
                          </td>
                          <td className="text-center">
                            {formatCurrency(rotasItem.charge)}
                          </td>
                          <td style={{ minWidth: '200px' }}>
                            <div>
                              <div>
                                {timing.accommodationExpensesByBusiness > 0
                                  ? formatExpense(
                                      timing.accommodationExpensesByBusiness ??
                                        0,
                                      rotasItem.placement.expenses
                                        ?.accommodation ?? 0,
                                      'By Business: ',
                                    )
                                  : formatExpense(
                                      timing.accommodationExpensesByCandidate,
                                      rotasItem.placement.expenses
                                        ?.accommodation ?? 0,
                                      'By Worker: ',
                                    )}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {formatExpense(
                              timing.travelExpenses,
                              rotasItem.placement.expenses?.travel ?? 0,
                            )}
                          </td>
                          <td className="text-center">
                            {formatExpense(
                              timing.foodExpenses,
                              rotasItem.placement.expenses?.food ?? 0,
                            )}
                          </td>
                          <td className="text-center">
                            {formatExpense(
                              timing.otherExpenses,
                              rotasItem.placement.expenses?.other ?? 0,
                            )}
                          </td>

                          <td className="text-center">
                            {timesheetStatus && (
                              <Badge
                                type={timesheetStatus?.type}
                                data-testid={`type-badge-${rotasItem._id}`}
                              >
                                {timesheetStatus?.label}
                              </Badge>
                            )}
                          </td>

                          <td
                            className="text-center"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {project.staffPayFrequency}
                          </td>
                          <td
                            className="text-center"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {rotasItem.placement.candidate?.paymentType}
                          </td>
                          <td className="text-center">
                            {formatCurrency(calculateTotalPayrollCost(timing))}
                          </td>
                          <td className="text-center ">
                            {formatCurrency(calculateTotalWorkerCost(timing))}
                          </td>

                          <td className="text-center ">
                            {formatDate(rotasItem.placement.confirmationSent)}
                          </td>
                          <td className="text-center">
                            {formatDate(rotasItem.placement.invoiceReceived)}
                          </td>
                          <td className="text-center">
                            {formatDate(rotasItem.placement.invoicePaid)}
                          </td>
                          <td className="text-center">
                            {rotasItem.placement.serviceLog}
                          </td>
                          <td className="text-center">
                            {rotasItem.placement.notes}
                          </td>
                          <td data-testid={`action-buttons-${rotasItem._id}`}>
                            <div className="action-item">
                              <div
                                className="view-icon disabled"
                                onClick={() => {}}
                                data-testid={`view-icon-${rotasItem._id}`}
                              >
                                <EyeIcon />
                              </div>
                              <div
                                className="edit-icon disabled"
                                onClick={() => {}}
                                data-testid={`edit-icon-${rotasItem._id}`}
                              >
                                <EditIcon />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="no-items-row"
                    >
                      No item found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        totalEntries={rotasList.length}
        data-testid="pagination-component"
      />
    </>
  );
};
